import { IRouteMenuData } from "utils/IRouteData";

import OnboardRoutes from "app-modules/onboard/_routemenu";


var ThemeRoutes: IRouteMenuData[] = [
	{
		navlabel: true,
		name: "Personal",
		icon: "mdi mdi-dots-horizontal",
	},

	OnboardRoutes,

	{
		path: "/",
		pathTo: "/onboard/999999999/view",
		name: "Dashboard",
		redirect: true,
	},
];

export default ThemeRoutes;
