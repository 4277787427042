import { FluentIconNames } from "app-model/FluentIconNames";

export const SysActions = {
	ACTIVATE: "ACTIVATE",
	ADDNOTES: "ADDNOTES",

	ADVANCE: "ADVANCE",

	APPLYTEMPLATE: "APPLYTEMPLATE",
	APPROVE: "APPROVE",

	ARCHIVEDOWNLOAD: "ARCHIVEDOWNLOAD",

	AUDITINTERNAL: "AUDITINTERNAL",
	AUDITEXTERNAL: "AUDITEXTERNAL",

	AUTOALLOT: "AUTOALLOT",

	CANCELORDERS: "CANCELORDERS",

	CERTRECEIVE: "CERTRECEIVE",
	
	CHANGE: "CHANGE",

	CHANGEACCT: "CHANGEACCT",

	CHANGEASSIGNEE: "CHANGEASSIGNEE",
	CHANGEFEE: "CHANGEFEE",
	CHANGEFEESETSPLIT: "CHANGEFEESETSPLIT",
	CHANGEOWNER: "CHANGEOWNER",
	CHANGEPARENT: "CHANGEPARENT",
	CHANGEPOSITION: "CHANGEPOSITION",
	CHANGEPAY: "CHANGEPAY",

	CHANGERATE: "CHANGERATE",

	CHANGETEMPLATE: "CHANGETEMPLATE",
	CHANGEUSERNAMEEMAIL: "CHANGEUSERNAMEEMAIL",

	CLEAR: "CLEAR",
	CLEARALL: "CLEARALL",

	CLEARDOWNLOAD: "CLEARDOWNLOAD",

	CLOSE: "CLOSE",

	CONVERT: "CONVERT",

	COPY: "COPY",

	COSTAUTOSETUP: "COSTAUTOSETUP",
	COSTAUTOSETUPALL: "COSTAUTOSETUPALL",
	COSTREBUILD: "COSTREBUILD",
	COSTREBUILDALL: "COSTREBUILDALL",
	
	CREATE: "CREATE",
	CREATECASE: "CREATECASE",
	
	CREATEMISSING: "CREATEMISSING",
	
	DEACTIVATE: "DEACTIVATE",

	DEALALLOTAPPROVE: "DEALALLOTAPPROVE",
	DEALALLOTAUTO: "DEALALLOTAUTO",
	DEALALLOTJOB: "DEALALLOTJOB",
	DEALALLOTRESEND: "DEALALLOTRESEND",
	DEALALLOTSEND: "DEALALLOTSEND",
	DEALALLOTUNAPPROVE: "DEALALLOTUNAPPROVE",
	DEALALLOTUNDO: "DEALALLOTUNDO",

	DELETE: "DELETE",

	DEMOBILIZE: "DEMOBILIZE",

	DEPRECIATE: "DEPRECIATE",
	DEPRECIATEALL: "DEPRECIATEALL",
	
	DISBURSE: "DISBURSE",

	DISCARD: "DISCARD",
	DISCARDSTALE: "DISCARDSTALE",
	
	DISPATCH: "DISPATCH",
	
	DISPOSE: "DISPOSE",

	DOCUMENTADD: "DOCUMENTADD",
	DOCUMENTCHECK: "DOCUMENTCHECK",
	DOCUMENTVERIFY: "DOCUMENTVERIFY",
	DOCUMENTVOID: "DOCUMENTVOID",
	
	ERECEIVE: "ERECEIVE",

	EXPORT: "EXPORT",

	FAREPOST: "FAREPOST",

	FETCH: "FETCH",

	FILTER: "FILTER",

	HANDOVER: "HANDOVER",

	HRCOMMUNICATE: "HRCOMMUNICATE",

	IMPORTVALID: "IMPORTVALID",

	INITDO: "INITDO",
	INITUNDO: "INITUNDO",
	INTERESTRUN: "INTERESTRUN",
	INTERESTRUNALL: "INTERESTRUNALL",
	INTERESTREWIND: "INTERESTREWIND",
	INTERESTREWINDALL: "INTERESTREWINDALL",
	INVALIDATE: "INVALIDATE",

	KILL: "KILL",

	LINK: "LINK",

	LIST: "LIST",
	LIST_CHANGEFILTER: "LIST_CHANGEFILTER",
	LIST_CHANGEPAGE: "LIST_CHANGEPAGE",
	LIST_CHANGESORT: "LIST_CHANGESORT",
	LIST_CMDSTART: "LIST_CMDSTART",
	LIST_CMDEND: "LIST_CMDEND",
	LIST_FETCHDATA: "LIST_FETCHDATA",
	LIST_RECEIVEDATA: "LIST_RECEIVEDATA",
	LIST_RESET: "LIST_RESET",
	LIST_SELECTITEMS: "LIST_SELECTITEMS",

	LOADDATA: "LOADDATA",

	LOADDEALS: "LOADDEALS",

	LOCK: "LOCK",

	LOGCALL: "LOGCALL",

	LOGOUTFIX: "LOGOUTFIX",

	MANUALDEALALLOT: "MANUALDEALALLOT",

	MARKETDATA: "MARKETDATA",
	
	MERGE: "MERGE",

	MIGRATECOMPANY: "MIGRATECOMPANY",

	MGTREVIEW: "MGTREVIEW",

	MOVENEXT: "MOVENEXT",
	MOVEPREVIOUS: "MOVEPREVIOUS",

	MOVETODATE: "MOVETODATE",
	
	OPEN: "OPEN",
	POST: "POST",
	POSTRANGE: "POSTRANGE",

	PRINT: "PRINT",

	PROCESS: "PROCESS",
	PROCESSORDERS: "PROCESSORDERS",

	PROPAGATE: "PROPAGATE",

	PURGE: "PURGE",
	PURGEDEALS: "PURGEDEALS",
	
	PWDCHANGE: "PWDCHANGE",
	PWDCHANGEOWN: "PWDCHANGEOWN",

	RECALL: "RECALL",
	RECATEGORIZE: "RECATEGORIZE",

	RECEIVE: "RECEIVE",

	RECOGNIZE: "RECOGNIZE",
	RECOMPUTE: "RECOMPUTE",
	RECOMPUTEALL: "RECOMPUTEALL",
	
	REFINANCE: "REFINANCE",
	
	REFRESH: "REFRESH",
	REJECT: "REJECT",
	
	REPOST: "REPOST",
	
	RESEND: "RESEND",

	RESPOND: "RESPOND",

	RETRIEVE: "RETRIEVE",
	RETURNOWNER: "RETURNOWNER",

	REVERSE: "REVERSE",
	REVERSERANGE: "REVERSERANGE",
	REVERSESTATUS: "REVERSESTATUS",

	REWIND: "REWIND",

	ROLLOVER: "ROLLOVER",
	RUN: "RUN",
	RUNALL: "RUNALL",
	RUNSCHEDULE: "RUNSCHEDULE",

	SAVE: "SAVE",
	SAVEALL: "SAVEALL",
	SAVEANDCLOSE: "SAVEANDCLOSE",
	SAVEANDNEW: "SAVEANDNEW",

	SCORE: "SCORE",
	SEARCH: "SEARCH",
	
	SECURITYSTATUS: "SECURITYSTATUS",

	SEND: "SEND",
	SETFEESPLIT: "SETFEESPLIT",
	SETTLE: "SETTLE",

	SETUPFEED: "SETUPFEED",

	SKIP: "SKIP",
	SPLIT: "SPLIT",

	STARTFIX: "STARTFIX",

	STATUS: "STATUS",
	STATUSORDERS: "STATUSORDERS",

	STOPFIX: "STOPFIX",

	SUBMIT: "SUBMIT",
	SUSPEND: "SUSPEND",

	TERMINATE: "TERMINATE",

	TRADECAPTURE: "TRADECAPTURE",

	UNAPPROVE: "UNAPPROVE",
	UNDEPRECIATE: "UNDEPRECIATE",
	UNDEPRECIATEALL: "UNDEPRECIATEALL",
	UNDISCARD: "UNDISCARD",
	UNFINALIZE: "UNFINALIZE",
	UNLOCK: "UNLOCK",
	UNPROCESS: "UNPROCESS",
	UNSETTLE: "UNSETTLE",

	UPDATE: "UPDATE",
	
	VALIDATEALL: "VALIDATEALL",

	VALIDATEDOWNLOAD: "VALIDATEDOWNLOAD",

	VALUATIONDO: "VALUATIONDO",
	VALUATIONDOALL: "VALUATIONDOALL",
	VALUATIONUNDO: "VALUATIONUNDO",
	VALUATIONUNDOALL: "VALUATIONUNDOALL",

	VAULTINOPS: "VAULTINOPS",
	VAULTINSALES: "VAULTINSALES",

	VERIFY: "VERIFY",

	VIEW: "VIEW",
	VIEW_CMDSTART: "VIEW_CMDSTART",
	VIEW_CMDEND: "VIEW_CMDEND",
		
	VIEW_FETCHDATA: "VIEW_FETCHDATA",
	VIEW_RECEIVEDATA: "VIEW_RECEIVEDATA",

	VIEWHISTORY: "VIEWHISTORY",
	VIEWLEDGER: "VIEWLEDGER",
	VIEWVALUATION: "VIEWVALUATION",
};

export type SysActions = typeof SysActions[keyof typeof SysActions];


export const SysActionFluentText = {
	ACTIVATE: "Activate",
	ADDNOTES: "Add Notes",

	ADVANCE: "Advance",

	APPLYTEMPLATE: "Apply Template",
	APPROVE: "Approve",

	ARCHIVEDOWNLOAD: "Archive Download",

	AUDITINTERNAL: "AUDITINTERNAL",
	AUDITEXTERNAL: "AUDITEXTERNAL",

	AUTOALLOT: "Auto Allot",

	CANCELORDERS: "Cancel Orders",

	CERTRECEIVE: "Receive Certificate",
	
	CHANGE: "Change",

	CHANGEACCT: "Change Acct",

	CHANGEASSIGNEE: "Change Assignee",
	CHANGEFEE: "Change Fee",
	CHANGEFEESETSPLIT: "Change Fee & Split",
	CHANGEOWNER: "Change Owner",
	CHANGEPARENT: "Change Parent",
	CHANGEPOSITION: "Change Position",
	CHANGEPAY: "Change Pay/Grade",

	CHANGERATE: "Change Rate",

	CHANGETEMPLATE: "Change Template",
	CHANGEUSERNAMEEMAIL: "Change UserName & Email",

	CLEAR: "Clear",
	CLEARALL: "Clear All",

	CLEARDOWNLOAD: "Clear Download",

	CLOSE: "Close",

	CONVERT: "Convert",

	COPY: "Copy",

	COSTAUTOSETUP: "AutoSetup Cost",
	COSTAUTOSETUPALL: "AutoSetup All Cost",
	COSTREBUILD: "Rebuild Cost",
	COSTREBUILDALL: "Rebuild All Cost",
	
	CREATE: "New",

	CREATECASE: "Create Case",
	
	CREATEMISSING: "Create Missing",
	
	DEACTIVATE: "Deactivate",

	DEALALLOTAPPROVE: "Approve Allotment",
	DEALALLOTAUTO: "Auto Allot",
	DEALALLOTJOB: "Allot Jobbing",
	DEALALLOTRESEND: "Resend Allotment",
	DEALALLOTSEND: "Send Allotment",
	DEALALLOTUNAPPROVE: "Revoke Allot. Approval",
	DEALALLOTUNDO: "Undo Allotment",

	DELETE: "Delete",

	DEMOBILIZE: "Demobilize",

	DEPRECIATE: "Depreciate",
	DEPRECIATEALL: "Depreciate All",

	DISBURSE: "Disburse",

	DISCARD: "Discard",
	DISCARDSTALE: "Discard Stale Items",
	
	DISPATCH: "Dispatch",
	
	DISPOSE: "Dispose",

	DOCUMENTADD: "Add Document",
	DOCUMENTCHECK: "Check Documents",
	DOCUMENTVERIFY: "Verify Documentation",
	DOCUMENTVOID: "Void Document",

	ERECEIVE: "E-Receipt",

	EXPORT: "Export",

	FAREPOST: "FAREPOST",

	FETCH: "Fetch",

	FILTER: "Filter",

	HANDOVER: "Hand Over",

	HRCOMMUNICATE: "HRCOMMUNICATE",

	IMPORTVALID: "Import Valid",

	INITDO: "Initialize",
	INITUNDO: "Uninitialize",
	INTERESTRUN: "Run Interest",
	INTERESTRUNALL: "Run All Interest",
	INTERESTREWIND: "Rewind Interest",
	INTERESTREWINDALL: "Rewind All Interest",
	INVALIDATE: "Invalidate",

	KILL: "Kill",

	LINK: "Link",

	LIST: "List",
	// LIST_CHANGEFILTER: "LIST_CHANGEFILTER",
	// LIST_CHANGEPAGE: "LIST_CHANGEPAGE",
	// LIST_CHANGESORT: "LIST_CHANGESORT",
	// LIST_FETCHDATA: "LIST_FETCHDATA",
	// LIST_RECEIVEDATA: "LIST_RECEIVEDATA",
	// LIST_RESET: "LIST_RESET",
	// LIST_SELECTITEMS: "LIST_SELECTITEMS",

	LOADDATA: "Load Data",

	LOADDEALS: "Load Deals",

	LOCK: "Lock",

	LOGCALL: "Log a Call",

	LOGOUTFIX: "FIX Logout",

	MANUALDEALALLOT: "Manual Allotment",

	MARKETDATA: "Market Data",
	
	MERGE: "Merge",

	MIGRATECOMPANY: "Migrate to Company",

	MGTREVIEW: "Mgt Review",

	MOVENEXT: "Next >>",
	MOVEPREVIOUS: "<< Previous",

	MOVETODATE: "Move to Date",
	
	OPEN: "Open",
	POST: "Post",
	POSTRANGE: "Post Range",

	PRINT: "Print",

	PROCESS: "Process",
	PROCESSORDERS: "Process Orders",

	PROPAGATE: "Propagate",

	PURGE: "Purge",
	PURGEDEALS: "Purge Deals",
	
	PWDCHANGE: "Change Password",
	PWDCHANGEOWN: "Change Own Password",

	RECALL: "Recall",
	RECATEGORIZE: "Recategorize",
	
	RECEIVE: "Receive",
	
	RECOGNIZE: "Recognize",
	RECOMPUTE: "Recompute",
	RECOMPUTEALL: "Recompute All",
	
	REFINANCE: "Refinance",
	
	REFRESH: "Refresh",
	REJECT: "Reject",
	
	REPOST: "Repost",
	
	RESEND: "Resend",

	RESPOND: "Respond",

	RETRIEVE: "Retrieve",
	RETURNOWNER: "Return to Owner",

	REVERSE: "Reverse",
	REVERSERANGE: "Reverse Range",
	REVERSESTATUS: "Rollback Status",

	REWIND: "Rewind",

	ROLLOVER: "Rollover",
	RUN: "Run",
	RUNALL: "Run All",
	RUNSCHEDULE: "Run on Schedule",

	SAVE: "Save",
	SAVEALL: "Save All",
	SAVEANDCLOSE: "Save & Close",
	SAVEANDNEW: "Save & New",

	SCORE: "Score",
	SEARCH: "Search",
	
	SECURITYSTATUS: "Security Status",

	SEND: "Send",
	SETFEESPLIT: "Set Split",
	SETTLE: "Settle",

	SETUPFEED: "Setup Feed",

	SKIP: "Skip",
	SPLIT: "Split",

	STARTFIX: "Start FIX",

	STATUS: "Status",
	STATUSORDERS: "Status of Orders",

	STOPFIX: "Stop FIX",

	SUBMIT: "Submit",
	SUSPEND: "Suspend",

	TERMINATE: "Terminate",

	TRADECAPTURE: "Capture Trades",

	UNAPPROVE: "Unapprove",
	UNDEPRECIATE: "Reverse Depreciation",
	UNDEPRECIATEALL: "Reverse All Depreciation",
	UNDISCARD: "Undiscard",
	UNFINALIZE: "Unfinalize",
	UNLOCK: "Unlock",
	UNPROCESS: "Unprocess",
	UNSETTLE: "Unsettle",

	UPDATE: "Edit",
	
	VALIDATEALL: "Validate All",

	VALIDATEDOWNLOAD: "Validate Download",

	VALUATIONDO: "Do Valuation",
	VALUATIONDOALL: "Do Valuation for All",
	VALUATIONUNDO: "Undo Valuation",
	VALUATIONUNDOALL: "Undo Valuation for All",

	VAULTINOPS: "Vault into Ops",
	VAULTINSALES: "Vault into Sales",

	VERIFY: "Verify",

	VIEW: "View",
	
	VIEWHISTORY: "View History",
	VIEWLEDGER: "View Ledger",
	VIEWVALUATION: "View Valuation",
	
	// VIEW_FETCHDATA: "VIEW_FETCHDATA",
	// VIEW_RECEIVEDATA: "VIEW_RECEIVEDATA",
};


export const SysActionFluentIcons = {
	//ACTIVATE: "cil-hand-point-up",
	ACTIVATE: FluentIconNames.ActivateOrders,
	
	//ADDNOTES: "mdi-clipboard-edit-outline",
	ADDNOTES: FluentIconNames.EditNote,
	
	ADVANCE: "mdi-forward",

	APPLYTEMPLATE: "APPLYTEMPLATE",

	//APPROVE: "mdi-thumb-up-outline",
	//APPROVE: "Accept",
	APPROVE: FluentIconNames.Like,

	ARCHIVEDOWNLOAD: "mdi-archive-arrow-down-outline",

	AUDITINTERNAL: "cil-print",
	AUDITEXTERNAL: "cil-external-link",

	AUTOALLOT: "mdi-order-alphabetical-ascending",

	CANCELORDERS: "mdi-text-box-remove-outline",

	//CERTRECEIVE: "mdi-certificate-outline",
	CERTRECEIVE: FluentIconNames.Certificate,
		
	//CHANGE: "cil-swap-horizontal",
	CHANGE: FluentIconNames.WindowEdit,

	CHANGEACCT: "cil-swap-horizontal",

	//CHANGEASSIGNEE: "CHANGEASSIGNEE",
	CHANGEASSIGNEE: FluentIconNames.Assign,

	CHANGEFEE: "cil-swap-horizontal",
	CHANGEFEESETSPLIT: "cil-swap-horizontal",

	CHANGEOWNER: "mdi-account-details",

	CHANGEPARENT: "cil-sitemap",
	CHANGEPOSITION: "cil-swap-horizontal",
	CHANGEPAY: "mdi-cash-refund",
	
	CHANGERATE: "cil-swap-horizontal",

	CHANGETEMPLATE: " mdi-clipboard-flow", 
	CHANGEUSERNAMEEMAIL: "mdi-account-edit-outline",

	CLEAR: "mdi-eraser",

	CLEARALL: "mdi-delete-sweep-outline",

	CLEARDOWNLOAD: "mdi-download-off-outline",

	//CLOSE: "cil-envelope-closed",
	//CLOSE: "Close",
	CLOSE: FluentIconNames.Folder,

	CONVERT: "cil-cog",

	//COPY: "mdi-content-copy",
	COPY: FluentIconNames.Copy,

	COSTAUTOSETUP: "cil-cog",
	COSTAUTOSETUPALL: "cil-cog",

	//COSTREBUILD: "cil-cog",
	COSTREBUILD: FluentIconNames.Build,

	//COSTREBUILDALL: "cil-cog",
	COSTREBUILDALL: FluentIconNames.BuildQueue,
	
	//CREATE: "mdi-plus",
	CREATE: FluentIconNames.Add,
	
	CREATECASE: "mdi-book-plus",
	
	CREATEMISSING: "mdi-book-plus",

	//DEACTIVATE: "mdi-bell-off-outline",
	DEACTIVATE: FluentIconNames.DeactivateOrders,

	DEALALLOTAPPROVE: "mdi-clipboard-check-outline",
	DEALALLOTAUTO: "cil-list-numbered",
	DEALALLOTJOB: "mdi-clipboard-list-outline",
	DEALALLOTRESEND: "cil-list-high-priority",
	DEALALLOTSEND: "cil-paper-plane",
	DEALALLOTUNAPPROVE: "cil-x",
	DEALALLOTUNDO: "cil-action-undo",

	//DELETE: "cil-trash",
	DELETE: FluentIconNames.Delete,

	DEMOBILIZE: "cil-trash",

	DEPRECIATE: "mdi-chart-ppf",
	DEPRECIATEALL: "mdi-chart-sankey",

	DISBURSE: "mdi-delete-empty-outline",

	//DISCARD: "mdi-delete-forever-outline",
	DISCARD: FluentIconNames.RecycleBin,
	
	DISCARDSTALE: "mdi-delete-sweep-outline",

	DISPATCH: "mdi-delete-empty-outline",

	DISPOSE: "mdi-delete-empty-outline",

	DOCUMENTADD: "mdi-book-plus-multiple-outline",
	DOCUMENTCHECK: "mdi-chart-sankey",
	DOCUMENTVERIFY: "mdi-download-circle-outline",
	DOCUMENTVOID: "mdi-book-remove-multiple-outline",

	ERECEIVE: "cil-share-boxed",

	//EXPORT: "mdi-export",
	EXPORT: FluentIconNames.Export,

	FAREPOST: "icon-cloud-upload",

	FETCH: "mdi-download-circle-outline",

	FILTER: FluentIconNames.Filter,

	HANDOVER: "cil-cog",

	HRCOMMUNICATE: "cil-voice-over-record",

	//IMPORTVALID: "mdi-import",
	IMPORTVALID: FluentIconNames.FabricMovetoFolder,

	INITDO: FluentIconNames.PlugConnected,
	INITUNDO: FluentIconNames.PlugDisconnected,
	
	INTERESTRUN: "mdi-chart-gantt",
	INTERESTRUNALL: "mdi-chevron-triple-up",
	INTERESTREWIND: "mdi-arrow-left-circle-outline",
	INTERESTREWINDALL: "mdi-chevron-triple-down",

	INVALIDATE: "mdi-file-remove-outline",

	KILL: "mdi-close",

	LINK: FluentIconNames.Link,

	LIST: "cil-list",

	//LOADDATA: "cil-reload",
	LOADDATA: FluentIconNames.Import,

	LOADDEALS: "mdi-file-document-outline",

	//LOCK: "mdi-lock-outline",
	LOCK: FluentIconNames.Lock,

	LOGCALL: "mdi-account-cog-outline",

	LOGOUTFIX: "mdi-account-cog-outline",

	MANUALDEALALLOT: "mdi-clipboard-text-outline",

	MARKETDATA: "mdi-chart-gantt",

	MERGE: "mdi-call-merge",

	MIGRATECOMPANY: "mdi-home-export-outline",

	MGTREVIEW: "mdi-file-eye-outline",
	
	MOVENEXT: "xxxx",
	MOVEPREVIOUS: "xxxx",
	
	MOVETODATE: "mdi-file-move-outline",
	
	//OPEN: "cil-envelope-open",
	OPEN: FluentIconNames.FolderOpen,

	POST: "mdi-table-lock",

	POSTRANGE: "mdi-arrow-expand-vertical",

	//PRINT: "cil-print",
	PRINT: FluentIconNames.Print,

	PROCESS: "cil-cog",
	PROCESSORDERS: "mdi-file-cog-outline",

	PROPAGATE: "mdi-content-copy",

	PURGE: "mdi-delete-empty-outline",
	PURGEDEALS: "mdi-delete-sweep-outliner",
	
	PWDCHANGE: "mdi-account-key-outline",
	PWDCHANGEOWN: "mdi-key",

	RECALL: "mdi-redo",
	RECATEGORIZE: "mdi-animation",
	
	RECEIVE: "mdi-animation",
	
	RECOGNIZE: "cil-search",
	RECOMPUTE: "mdi-keyboard",
	RECOMPUTEALL: "mdi-desktop-mac-dashboard",

	REFINANCE: "mdi-refresh",

	//REFRESH: "mdi-refresh",
	REFRESH: FluentIconNames.Refresh,

	//REJECT: "mdi-thumb-down-outline",
	REJECT: FluentIconNames.Dislike,

	REPOST: "mdi-mailbox-up-outline",

	RESEND: "mdi-debug-step-over",

	RESPOND: "mdi-mailbox-up-outline",
	
	RETRIEVE: "mdi-replay",
	RETURNOWNER: "mdi-account-convert-outline",

	REVERSE: "mdi-restore",
	REVERSERANGE: "cil-list-low-priority",
	REVERSESTATUS: "cil-list-low-priority",

	REWIND: "cil-media-skip-backward",
	
	ROLLOVER: "cil-list-high-priority",

	// RUN: "mdi-autorenew",
	//RUN: "TriggerUser",
	RUN: FluentIconNames.Play,

	RUNALL: "mdi-atom-variant",
	
	//RUNSCHEDULE: "mdi-calendar-sync-outline",
	RUNSCHEDULE: FluentIconNames.TriggerAuto,

	//SAVE: "cil-save",
	SAVE: FluentIconNames.Save,
	SAVEALL: FluentIconNames.SaveAll,
	SAVEANDCLOSE: FluentIconNames.SaveAndClose,
	SAVEANDNEW: FluentIconNames.SaveTemplate,

	SCORE: "mdi-clipboard-edit-outline",

	//SEARCH: "cil-search",
	SEARCH: FluentIconNames.Search,
	
	SECURITYSTATUS: "mdi-chart-gantt",

	//SEND: "cil-send",
	//SEND: "MailForward",
	SEND: FluentIconNames.Send,
	
	SETFEESPLIT: "mdi-call-split",
	SETTLE: "cil-wc",

	SETUPFEED: "mdi-rss",

	SKIP: "mdi-debug-step-over",

	//SPLIT: "mdi-call-split",
	SPLIT: FluentIconNames.BranchFork,

	STARTFIX: "mdi-tools",

	STATUS: "cil-signal-cellular3",
	STATUSORDERS: "mdi-script-text-outline",

	STOPFIX: "mdi-fruit-citrus-off", 

	SUBMIT: "mdi-file-upload-outline", 
	SUSPEND: "mdi-play-pause",

	TERMINATE: "mdi-close",

	TRADECAPTURE: "mdi-chart-line",

	UNAPPROVE: "cil-x",
	UNDEPRECIATE: "cil-sort-alpha-up",
	UNDEPRECIATEALL: "cil-sort-ascending",

	//UNDISCARD: "cil-action-undo",
	UNDISCARD: FluentIconNames.EmptyRecycleBin,

	//UNFINALIZE: "mdi-sort-variant-remove",
	UNFINALIZE: FluentIconNames.RemoveFromTrash,

	//UNLOCK: "mdi-lock-open-variant-outline",
	UNLOCK: FluentIconNames.Unlock,
	
	UNPROCESS: "cil-action-undo",
	UNSETTLE: "mdi-scale-balance",

	//UPDATE: "mdi-file-document-edit-outline",
	UPDATE: FluentIconNames.Edit,
	
	VALIDATEALL: "mdi-book-check-outline",

	VALIDATEDOWNLOAD: "mdi-check-underline-circle-outline",

	VALUATIONDO: "cil-arrow-top",
	VALUATIONDOALL: "cil-sort-ascending",
	VALUATIONUNDO: "cil-action-undo",
	VALUATIONUNDOALL: "cil-sort-descending",

	VAULTINOPS: "mdi-safe-square-outline",
	VAULTINSALES: "mdi-safe",
	
	VERIFY: "mdi-book-check-outline",

	//VIEW: "mdi-eye",
	VIEW: FluentIconNames.View,
	
	VIEWHISTORY: "mdi-table-account",

	//VIEWLEDGER: "mdi-table-account",
	VIEWLEDGER: FluentIconNames.EntryView,

	VIEWVALUATION: "mdi-table-account",
};
