import { lazy } from "react";

const WillDraftView = lazy(() => import("app-modules/willdraft/WillDraftMaster"));

const moduleRoutes = 	{
	//collapse: true,
	// template: "/wills/:id/view/*",
	//path: "/willdraft/999999999/view/*",
	path: "/wills/*", //'wills' 'wills/2', 'wills/joshua' wills/* wills
	name: "WillDrafting",
	state: "willdraftpages",
	icon: "codepen",
	component: WillDraftView,
};

// <Route/>

export default moduleRoutes;
