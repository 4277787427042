import { $isNull, IFunc4 } from 'app-helpers/basicFunctions';

import { fetchApiRestListData, fetchApiEntityData, fetchApiOdataListData } from "app-helpers/apiHelpers";

import { SysActions } from "app-model/SysActions";

export function doListCmdStart(dispatch: (action: any) => void, listName: string) {
	dispatch({ 
		type: listName + '/' + SysActions.LIST_CMDSTART, 
		payload: { listName: listName } 
	});
}

export function doListCmdEnd(dispatch: (action: any) => void, listName: string) {
	dispatch({ 
		type: listName + '/' + SysActions.LIST_CMDEND, 
		payload: { listName: listName } 
	});
}

export function doViewCmdStart(dispatch: (action: any) => void, viewName: string) {
	dispatch({ 
		type: viewName + '/' + SysActions.VIEW_CMDSTART, 
		payload: { viewName: viewName } 
	});
}

export function doViewCmdEnd(dispatch: (action: any) => void, viewName: string) {
	dispatch({ 
		type: viewName + '/' + SysActions.VIEW_CMDEND, 
		payload: { viewName: viewName } 
	});
}

export function doApiOdataListFetch(
	listName: string,
	getListUrl: IFunc4<number, number, any, string[], string>,
	pageToFetch: number,
	sizePage: number,
	filterValues: any,
	filterExpressions: string[]
): (dispatch: (action: any) => void) => Promise<any> {
	return async (dispatch) => {
		dispatch({ 
			type: listName + '/' + SysActions.LIST_FETCHDATA, 
			payload: { listName: listName, filterValues: filterValues, filterExpressions: filterExpressions, sizePage: sizePage, currentPage: pageToFetch } 
		});

		//console.log(`doApiOdataListFetch: page - ${pageToFetch}, filters - ${filterValues}`);
		//console.log(`doApiOdataListFetch: page - ${pageToFetch}, sizePage - ${sizePage}`);

		const fetchUrl = getListUrl(pageToFetch, sizePage, filterValues, filterExpressions);

		// console.log(fetchUrl);

		const listData = await fetchApiOdataListData(fetchUrl, pageToFetch, sizePage);

		//console.log(listData);

		dispatch({ type: listName + '/' + SysActions.LIST_RECEIVEDATA, payload: { listName: listName, data: listData, filterValues: filterValues, filterExpressions: filterExpressions } });
	};
}

export function doApiRestListFetch(
	listName: string,
	getListUrl: IFunc4<number, number, any, string[], string>,
	pageToFetch: number,
	sizePage: number,
	filterValues: any
): (dispatch: (action: any) => void) => Promise<any> {

	return async (dispatch) => {
		dispatch({ 
			type: listName + '/' + SysActions.LIST_FETCHDATA, 
			payload: { listName: listName, filterValues: filterValues, sizePage: sizePage, currentPage: pageToFetch } 
		});

		// console.log(`doApiRestListFetch: page - ${pageToFetch}, filters - ${filterValues}`);
		// console.log(`doApiRestListFetch: page - ${pageToFetch}, sizePage - ${sizePage}`);

		const fetchUrl = getListUrl(pageToFetch, sizePage, filterValues);

		console.log(fetchUrl);

		const listData = await fetchApiRestListData(fetchUrl, pageToFetch, sizePage);

		console.log(listData);

		dispatch({ 
			type: listName + '/' + SysActions.LIST_RECEIVEDATA, 
			payload: { listName: listName, data: listData, filterValues: filterValues } 
		});
	};

}

export function doApiEntityFetch(
	viewName: string,
	getEntityUrl: (entityId: number) => string,
	entityId: number,
	fetchRelatedData: (entityId: number, entityData: any, urlParams?: any) => Promise<any>,
	urlParams?: any
): (dispatch: (action: any) => void) => void {

	return async (dispatch) => {
		dispatch({ 
			type: viewName + '/' + SysActions.VIEW_FETCHDATA, 
			payload: { viewName: viewName, entityId: entityId } 
		});

		// console.log(`ApiListFetch: page - ${pageToFetch}, filters - ${filterValues}`);

		const fetchUrl = getEntityUrl(entityId);

		//console.log(fetchUrl);

		const viewData = await fetchApiEntityData(fetchUrl); //entityData, error, entityId, relatedData

		//console.log(viewData);

        const relatedData = 
            $isNull(fetchRelatedData) 
            ? {} 
            : await fetchRelatedData(entityId, viewData.entityData, urlParams);

		dispatch({ type: viewName + '/' + SysActions.VIEW_RECEIVEDATA, payload: { viewName: viewName, data: { ...viewData, entityId: entityId, relatedData } } });
	};

}
