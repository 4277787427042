import { lazy } from "react";

const VgstIndividualMaster = lazy(() => import("app-modules/vgst/individual/IndividualMaster"));

const moduleRoutes = {

	//collapse: true,
	// template: "/wills/:id/view/*",
	//path: "/willdraft/999999999/view/*",
	path: "/vgsthome/individual/*",
	name: "VGSTIndividual",
	state: "vgstpages",
	icon: "codepen",
	component: VgstIndividualMaster,
};

export default moduleRoutes;
