import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';

import * as serviceWorker from 'app/serviceWorker';
import store from 'app-redux/Store';
import "index.css";

import "assets/scss/style.scss";
// import "assets/scss/style.css";

import "assets/css/preauth-index.css";
import "assets/css/sidebar.css";


import App from 'app/App';
import { msalConfig, msalInstance } from 'adalConfig';
// import Customizer from "app-layouts/layout-components/customizer/Customizer";

const rootElement = document.getElementById("root");
// window.location.reload();
if (rootElement) {
	// Create a root.
	const root = createRoot(rootElement);

	root.render(
		// <StrictMode>
			<Provider store={store}>
				<MsalProvider instance={msalInstance}>
					<App />
				</MsalProvider>
			</Provider>
		// </StrictMode>
	);
} else {
	throw new Error("Could not find root element to mount to!");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
