import { IReduxStoreProps } from "app-framework/AppBaseInterfaces";

export const userMapState = (state): IReduxStoreProps => {
	return {
		controlState: null,
		requiredGlobals: {
			Global_MyUserView: state.globals.Global_MyUserView,
		},
	};
};

export const sessionMapState = (state): any => {
	return {
		asideShow: state.session.asideShow,
		darkMode: state.session.darkMode,
		sidebarShow: state.session.sidebarShow,
	};
};
