import { FunctionComponent, lazy, Suspense, useEffect } from "react";
import { HashRouter, Navigate, NavLink, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { $isNull } from "app-helpers/basicFunctions";
import { UserModeEnum } from "app-model/enums/SysEnums";
import { IUser } from "app-model/IUser";

import { IReduxStoreProps } from "app-framework/AppBaseInterfaces";
import { userMapState } from "app-framework/AppCommonReduxMaps";
import { ensureRequiredGlobals } from "app-framework/AppDataHelpers";
import Spinner from "app-framework/spinner/Spinner";

import GeneralRoutes from "app-layouts/GeneralRoutes";
import FullMenuRoutes from "app-layouts/FullMenuRoutes";
import OnboardMenuRoutes from "app-layouts/OnboardMenuRoutes";

import RouteMapper from "app-layouts/RouteMapper";
import { Container } from "reactstrap";

// import "assets/css/Notes.css"

const FullLayout = lazy(() => import("app-layouts/FullLayout"));

export const LogonAuthenticatedRouteMap: FunctionComponent = () => {
	const reduxProps = useSelector<any, IReduxStoreProps>(userMapState);
	const dispatch = useDispatch();

	const userData: IUser = reduxProps.requiredGlobals.Global_MyUserView.entityData;

	// Check usermodes (from API) to decide which set of routes to display to the user
	const themeRoutes =
		$isNull(userData.UserMode) ? []
			: userData.UserMode == UserModeEnum.SteadyState ? [...FullMenuRoutes]
				: userData.UserMode == UserModeEnum.Reviewing ? [...FullMenuRoutes/* ...ReviewingMenuRoutes */]
					: [...OnboardMenuRoutes];



	console.log('userData.UserMode - ', userData.UserMode);

	console.log('themeRoutes - ', themeRoutes);

	useEffect(
		() => {
			ensureRequiredGlobals({ dispatch, ...reduxProps });
		},
		[]
	);

	console.log(themeRoutes, FullMenuRoutes, "ThemeRoute");
	// /reviewing/999999999/view
	return (
		<Routes>

			{GeneralRoutes.map((prop, key) => RouteMapper({ routeData: prop, routeKey: key }))}

			<Route path="/" element={<FullLayout />}>
				{$isNull(userData.UserMode)
					? null //no other routes will be defined, we will fall through to the catch-all route
					: themeRoutes.map((prop, key) => RouteMapper({ routeData: prop, routeKey: key }))}

				{/* //catch-all route */}
				<Route path="*" element={
					<Container>

						<div className='text-center bg-[transparent] h-[90vh] flex flex-col items-center justify-center font-normal gap-y-3 p-3 lg:p-0'>
							<p className='text-3xl font-semibold text-center leading-[30px] '>
								Your Information Is Processing!
								{/* Your account is in review and you may not have access <br /> to this information at the moment. */}
							</p>
							<p className='text-xl font-normal text-center leading-[30px] animate-pulse'>
								Please wait!
							</p>
							<p className='text-base font-light mt-8'>
								You can explore our other services
								on our <NavLink to='/home' className=' text-blue-700 hover:underline hover:font-black hover:text-vetivaGreen duration-500'>Homepage</NavLink>
							</p>

						</div>
					</Container>
				} />

			</Route>
		</Routes>
	);
};
