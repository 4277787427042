/*** Status for Certificates */
export const CertClosure = {
	/*** Indicates a certificate that has been invalidated. */
	Invalidated: 'Invalidated', //-10,
	/*** Indicates a certificate that has been received and is in a pending state awaiting further processing. */
	Pending: 'Pending', //-1,
	/*** Indicates a certificate that has is going through processing and validation. */
	Processing: 'Processing', //0,
	/*** Indicates a certificate that has been dematerialised in the CSD. */
	Dematerialized: 'Dematerialized', //1,
	/*** Indicates a certificate that has been retrieved by the Registrars. */
	Retrieved: 'Retrieved', //2,
	/*** Indicates a certificate that has been returned to the owner. */
	ReturnedOwner: 'ReturnedOwner', //3
} as const;

export type CertClosure = typeof CertClosure[keyof typeof CertClosure];


/*** Status for Certificate Deposits */
export const CertDepositClosure = {
	/*** Indicates a pending deposit */
	Pending: 'Pending', //-1,
	/*** Indicates a processed deposit */
	Processed: 'Processed', //0
} as const;

export type CertDepositClosure = typeof CertDepositClosure[keyof typeof CertDepositClosure];


/// <summary>Indicates the type of an investment certificate.</summary>
export const CertType = {
	/// <summary>Indicates a certificate that has no particular source transaction type assocaited with it.</summary>
	NA: 'NA', //0,
	/// <summary>Indicates a certificate given from a PryIssue.</summary>
	P_ISSUE: 'P_ISSUE', //1,
	/// <summary>Indicates a certificate from a Split.</summary>
	SPLIT: 'SPLIT', //2,
	/// <summary>Indicates a certificate issued from a Merger.</summary>
	MERGER: 'MERGER', //4,
	/// <summary>Indicates a certificate from a Reverse Split.</summary>
	SPLIT_R: 'SPLIT_R', //8
} as const;

export type CertType = typeof CertType[keyof typeof CertType];


export const ContractClosure = {
	Reversed: 'Reversed', //-2,
	Partial: 'Partial', //-1,
	Posted: 'Posted', //0
};

export type ContractClosure = typeof ContractClosure[keyof typeof ContractClosure];


/*** Members of this enum indicate the state of a corporate action record */
export const CorpActionClosure = {
	/*** Indicates that a corporate action has been discarded */
	Discarded: 'Discarded', //-2,
	/*** Indicates that a corporate action is pending processing */
	Pending: 'Pending', //-1,
	/*** Indicates that a corporate action has been approved as a valid record and can be propagated to clients */
	Approved: 'Approved', //0,
	/*** Indicates that direct processing on a corporate action has ceased */
	Closed: 'Closed', //1,
	/*** Indicates that a corporate action has been partially alloted to beneficiaries */
	AllotmentPartial: 'AllotmentPartial', //2,
	/*** Indicates that a corporate action has been fully alloted to beneficiaries */
	AllotmentFull: 'AllotmentFull', //3
} as const;

export type CorpActionClosure = typeof CorpActionClosure[keyof typeof CorpActionClosure];


export const CorpActionPryIssueTypeEnum = {
	OFFER: 'OFFER', //1,
	PRIVATE: 'PRIVATE', //2,
	RIGHT: 'RIGHT', //4,
	RIGHT_OFFER: 'RIGHT_OFFER', //RIGHT | OFFER, //5
	RIGHT_PRIVATE: 'RIGHT_PRIVATE', //RIGHT | PRIVATE, //6
	SALE: 'SALE', //8,
};

export type CorpActionPryIssueTypeEnum = typeof CorpActionPryIssueTypeEnum[keyof typeof CorpActionPryIssueTypeEnum];


/*** Members of this enum indicate the state or issues of a holdings/position resolver record */
export const CSCSResolverClosure = {
	/*** Indicates an account that is not setup to receive holdings */
	NonReceptiveAccount: 'NonReceptiveAccount', //-5,
	/*** Indicates a position record where there are multiple accounts with the same AccountNo */
	MultipleAccounts: 'MultipleAccounts', //-4,
	/*** A position record that cannot be reconciled because of an instrument that is missing or not properly setup */
	MissingInstrument: 'MissingInstrument', //-3,
	/*** A position record that cannot be reconciled because of a trading account that is missing or not properly setup */
	MissingAccount: 'MissingAccount', //-2,
	/*** A position record that has not been resolved */
	Unresolved: 'Unresolved', //-1,
	/*** A position record that has been fully reconciled manually */
	Sorted: 'Sorted', //0
	/*** A position record that the system was able to auto-reconcile */
	AutoReconciled: 'AutoReconciled', //1
} as const;

export type CSCSResolverClosure = typeof CSCSResolverClosure[keyof typeof CSCSResolverClosure];


/*** An enum that describes the various states of an IDataSheet entity */
export const DataSheetClosure = {
	/*** Indicates that the DataSheet is in an invalid state */
	Invalid: 'Invalid', //-100,
	/*** Indicates that the DataSheet is open and can receive entries */
	Open: 'Open', //-1,
	/*** Indicates that the DataSheet is closed to new entries */
	Closed: 'Closed', //0,
	/*** Indicates that the DataSheet has been processed and side effects accounted for */
	Processed: 'Processed', //1,
	/*** Indicates a DataSheet whose contents have been archived */
	Archived: 'Archived', //4,
} as const;

export type DataSheetClosure = typeof DataSheetClosure[keyof typeof DataSheetClosure];


/*** An enum that describes the various states of an IDataSheet entity */
export const DealAllotClosure = {
	Reposting: 'Reposting', //-10,
	Discarded: 'Discarded', //-2,
	Pending: 'Pending', //-1,
	ApprovedForDispatch: 'ApprovedForDispatch', //0, //Supervisor manually approves for dispatch pending matching and resolution
	Matched: 'Matched', //1,
	Posted: 'Posted', //2
} as const;

export type DealAllotClosure = typeof DealAllotClosure[keyof typeof DealAllotClosure];


/*** Enum indicating the status of a DealSheet */
export const DealSheetClosure = {
	/*** Marks a DealSheet thas has been invalidated; usually due to a cancelled trading session */
	Invalidated: 'Invalidated', //-100,
	/*** Show a new/pristine DealSheet */
	New: 'New', //-2,
	/*** Identifies a DealSheet that is receiving orders */
	OpenForOrders: 'OpenForOrders', //-1,
	/*** Identifies a DealSheet that is no longer receiving orders */
	ClosedForOrders: 'ClosedForOrders', //0,
	/*** Signifies a DealSheet in Revision status */
	DealRevision: 'DealRevision', //1,
	/*** Shows a DealSheet that has been processed */
	DealsProcessed: 'DealsProcessed', //2,
	/*** Shows a posted DealSheet */
	DealsPosted: 'DealsPosted', //3,
	/*** Indicates deals have been settled */
	DealsSettled: 'DealsSettled', //4,
	/*** Marks an archived DealSheet */
	Archived: 'Archived', //10,
} as const;

export type DealSheetClosure = typeof DealSheetClosure[keyof typeof DealSheetClosure];


export const DealSource = {
	NA: 'NA', //0,
	CSD: 'CSD', //1,
	EXCH_TRMNL: 'EXCH_TRMNL', //2,
	FIX: 'FIX', //3,
	CUSTOM: 'CUSTOM', //4,
	EXCH_PRTL: 'EXCH_PRTL', //5
};

export type DealSource = typeof DealSource[keyof typeof DealSource];


/*** An enum indicating the type of delisting that took place. */
export const DelistTypeEnum = {
	/*** Indicates a straight delisting of all stock. */
	Delist: 'Delist', //1,
	/*** Indicates a delisting arising from maturity of an instrument (such as a bond). */
	Maturity: 'Maturity', //2,
	/*** Indicates a delisting of shares (usually partial) arising from the recall/repurchase of some of them from the open market. */
	Recall: 'Recall', //4
} as const;

export type DelistTypeEnum = typeof DelistTypeEnum[keyof typeof DelistTypeEnum];


export const DividendType = {
	Q1: 'Q1', //1,
	Q2: 'Q2', //2,
	Q3: 'Q3', //3,
	INTERIM: 'INTERIM', //10,
	FULL: 'FULL', //20
};

export type DividendType = typeof DividendType[keyof typeof DividendType];


export const EQAccountClassEnum = {
	Exchange: 'Exchange', //1,
	Nominee: 'Nominee', //2,
	OverTheCounter: 'OverTheCounter', //4
}

export type EQAccountClassEnum = typeof EQAccountClassEnum[keyof typeof EQAccountClassEnum];


export const canClassTieToExchange = (val: EQAccountClassEnum) => val === EQAccountClassEnum.Exchange || val === EQAccountClassEnum.Nominee;


export const EQAccountTypeEnum = {
	// _Exchange = 1,
	// _Nominee = 2,
	// _OverTheCounter = 4,

	// _MktMaker = 8, //CanTrade = true,
	// _Trading = 16, //CanTrade = true,
	// _Tracking = 32, //CanTrade = false,

	// _Collateral = 64,
	// _Custodian = 128,
	// _SecLendBorrower = 256,
	// _SecLendLender = 512,

	// _SettleHoldings = 1024,
	// _SettleConsideration = 2048,
	// _CanSettleUniquely = 4096,

	// _FundSubscription = 8192,

	// _SchemeAccount = 16384,

	// _DirectCashSettle = 32768,


	ExchCollateralCustody: 'ExchCollateralCustody', //_Exchange | _Trading | _Collateral | _Custodian | _SettleConsideration | _CanSettleUniquely, //ConduitCollateral = 385 -> 6353
	ExchCollateralTracking: 'ExchCollateralTracking', //_Exchange | _Tracking | _Collateral | _SettleHoldings, //RegularCollateral = 257 -> 1121
	ExchCollateralTrade: 'ExchCollateralTrade', //_Exchange | _Trading | _Collateral | _Custodian | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //PartnerCustodian = 513 -> 7377

	ExchConduit: 'ExchConduit', //_Exchange | _Trading | _Custodian, //RegularConduit = 129 -> 161 -> 145

	ExchMktMaker: 'ExchMktMaker', //_Exchange | _MktMaker | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //MktMakerDirect = 17 -> 7177, MktMakerLeveraged = 1041 -> 7177 

	ExchRegularTrade: 'ExchRegularTrade', //_Exchange | _Trading | _SettleHoldings | _SettleConsideration, //RegularExchange = 1 -> 3089 

	//ExchRegularWithDCS: 'ExchRegularWithDCS', //_Exchange | _Trading | _SettleHoldings | _SettleConsideration | _DirectCashSettle, //35857

	//ExchTradeConsidWithDCS: 'ExchTradeConsidWithDCS', //_Exchange | _Trading | _SettleConsideration | _DirectCashSettle, //34833

	ExchSecLendBorrower: 'ExchSecLendBorrower', //_Exchange | _Tracking | _SecLendBorrower | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //TrackingBorrower = 97 -> 7457
	ExchSecLendLender: 'ExchSecLendLender', //_Exchange | _Tracking | _SecLendLender | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //TrackingLender = 2065 -> 7713

	ExchSpecialTrade: 'ExchSpecialTrade', //_Exchange | _Trading | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, // -> 7185 

	NomCollateralCustody: 'NomCollateralCustody', //_Nominee | _Trading | _Custodian | _Collateral | _SettleConsideration,
	NomCollateralTrade: 'NomCollateralTrade', //_Nominee | _Trading | _Custodian | _Collateral | _SettleHoldings | _SettleConsideration, //CollateralNominee = 258 -> 3282

	NomConduit: 'NomConduit', //_Nominee | _Trading | _Custodian, //ConduitNominee = 130 -> 146
	NomRegularTrade: 'NomRegularTrade', //_Nominee | _Trading | _SettleHoldings | _SettleConsideration, //RegularNominee = 3 -> 3090

	//NomRegularWithDCS: 'NomRegularWithDCS', //_Nominee | _Trading | _SettleHoldings | _DirectCashSettle, //

	NomSchemeTrade: 'NomSchemeTrade', //_Nominee | _Trading | _SettleHoldings | _SettleConsideration | _SchemeAccount, //

	//NomSchemeWithDCS: 'NomSchemeWithDCS', //_Nominee | _Trading | _SettleHoldings | _SchemeAccount | _DirectCashSettle, //

	NomSecLendBorrower: 'NomSecLendBorrower', //_Nominee | _Tracking | _SecLendBorrower | _SettleHoldings | _SettleConsideration | _CanSettleUniquely,
	NomSecLendLender: 'NomSecLendLender', //_Nominee | _Tracking | _SecLendLender | _SettleHoldings | _SettleConsideration | _CanSettleUniquely,

	OtcFundAccount: 'OtcFundAccount', //_OverTheCounter | _Tracking | _SettleHoldings | _SettleConsideration | _FundSubscription, //11300

	OtcRegularTrade: 'OtcRegularTrade', //_OverTheCounter | _Trading | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //OverTheCounter = 4 -> 7188

	//ConduitWarehouse: 'ConduitWarehouse', //4225, //_Exchange + _Warehouse + _CNDT,
	//CollateralWarehouse: 'CollateralWarehouse', //4353, //_Exchange + _Warehouse + _Collateral,
	//RegularWarehouse: 'RegularWarehouse', //4097, //_Exchange + _Warehouse,
}

export type EQAccountTypeEnum = typeof EQAccountTypeEnum[keyof typeof EQAccountTypeEnum];


export interface IEQAccountTypeEnumGroupEntries {
	[key: string]: EQAccountTypeEnum;
}

export interface IEQAccountTypeEnumGroup {
	default: EQAccountTypeEnum;
	entries: IEQAccountTypeEnumGroupEntries;
}

export interface IEQAccountTypeLookup {
	[key: string]: IEQAccountTypeEnumGroup;
}

export const EQAccountTypeLookup: IEQAccountTypeLookup = {
	Exchange: {
		default: EQAccountTypeEnum.ExchRegularTrade,
		entries: {
			ExchCollateralCustody: 'ExchCollateralCustody', //_Exchange | _Trading | _Collateral | _Custodian | _SettleConsideration | _CanSettleUniquely, //ConduitCollateral = 385 -> 6353
			ExchCollateralTracking: 'ExchCollateralTracking', //_Exchange | _Tracking | _Collateral | _SettleHoldings, //RegularCollateral = 257 -> 1121
			ExchCollateralTrade: 'ExchCollateralTrade', //_Exchange | _Trading | _Collateral | _Custodian | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //PartnerCustodian = 513 -> 7377

			ExchConduit: 'ExchConduit', //_Exchange | _Trading | _Custodian, //RegularConduit = 129 -> 161 -> 145

			ExchMktMaker: 'ExchMktMaker', //_Exchange | _MktMaker | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //MktMakerDirect = 17 -> 7177, MktMakerLeveraged = 1041 -> 7177 

			ExchRegularTrade: 'ExchRegularTrade', //_Exchange | _Trading | _SettleHoldings | _SettleConsideration, //RegularExchange = 1 -> 3089 

			//ExchRegularWithDCS: 'ExchRegularWithDCS', //_Exchange | _Trading | _SettleHoldings | _SettleConsideration | _DirectCashSettle, //35857

			//ExchTradeConsidWithDCS: 'ExchTradeConsidWithDCS', //_Exchange | _Trading | _SettleConsideration | _DirectCashSettle, //34833

			ExchSecLendBorrower: 'ExchSecLendBorrower', //_Exchange | _Tracking | _SecLendBorrower | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //TrackingBorrower = 97 -> 7457
			ExchSecLendLender: 'ExchSecLendLender', //_Exchange | _Tracking | _SecLendLender | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //TrackingLender = 2065 -> 7713

			ExchSpecialTrade: 'ExchSpecialTrade', //_Exchange | _Trading | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, // -> 7185 
		}
	},
	Nominee: {
		default: EQAccountTypeEnum.NomRegularTrade,
		entries: {
			NomCollateralCustody: 'NomCollateralCustody', //_Nominee | _Trading | _Custodian | _Collateral | _SettleConsideration,
			NomCollateralTrade: 'NomCollateralTrade', //_Nominee | _Trading | _Custodian | _Collateral | _SettleHoldings | _SettleConsideration, //CollateralNominee = 258 -> 3282

			NomConduit: 'NomConduit', //_Nominee | _Trading | _Custodian, //ConduitNominee = 130 -> 146
			NomRegularTrade: 'NomRegularTrade', //_Nominee | _Trading | _SettleHoldings | _SettleConsideration, //RegularNominee = 3 -> 3090

			//NomRegularWithDCS: 'NomRegularWithDCS', //_Nominee | _Trading | _SettleHoldings | _DirectCashSettle, //

			NomSchemeTrade: 'NomSchemeTrade', //_Nominee | _Trading | _SettleHoldings | _SettleConsideration | _SchemeAccount, //

			//NomSchemeWithDCS: 'NomSchemeWithDCS', //_Nominee | _Trading | _SettleHoldings | _SchemeAccount | _DirectCashSettle, //

			NomSecLendBorrower: 'NomSecLendBorrower', //_Nominee | _Tracking | _SecLendBorrower | _SettleHoldings | _SettleConsideration | _CanSettleUniquely,
			NomSecLendLender: 'NomSecLendLender', //_Nominee | _Tracking | _SecLendLender | _SettleHoldings | _SettleConsideration | _CanSettleUniquely,
		}
	},
	OverTheCounter: {
		default: EQAccountTypeEnum.OtcRegularTrade,
		entries: {
			OtcFundAccount: 'OtcFundAccount', //_OverTheCounter | _Tracking | _SettleHoldings | _SettleConsideration | _FundSubscription, //11300

			OtcRegularTrade: 'OtcRegularTrade', //_OverTheCounter | _Trading | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //OverTheCounter = 4 -> 7188
		}
	}
};


export const isAcctTypeOfClass = (val: EQAccountTypeEnum, iclass: EQAccountClassEnum) =>
	Object.entries(EQAccountTypeLookup[iclass].entries).map(entry => entry[1]).findIndex(entry => entry === val) > -1;


export const canTypeTieToExchange = (val: EQAccountTypeEnum) => {
	let result = false;

	//check the Exchange entries
	result =
		Object.entries(EQAccountTypeLookup[EQAccountClassEnum.Exchange].entries).map(entry => entry[1]).findIndex(entry => entry === val) > -1;

	//if the val was not amongst the Exchange entries, check the Nominee entries
	if (result === false)
		result =
			Object.entries(EQAccountTypeLookup[EQAccountClassEnum.Nominee].entries).map(entry => entry[1]).findIndex(entry => entry === val) > -1;

	//return the result
	return result;
};


/*** This enum indicates the class of an investment instrument */
export const EQInstrumentClassEnum = {
	/*** Indicates an instrument that is a Bond */
	Bonds: 'Bonds', //100,
	/*** Indicates an instrument that is a tradeable Commodity */
	Commodity: 'Commodity', //200,
	/*** Indicates an instrument that is Currency */
	Currency: 'Currency', //300,
	/*** Indicates an instrument that is Equity */
	Equity: 'Equity', //400,
	/*** Indicates an instrument that is a Fund */
	Fund: 'Fund', //500,
	/*** Indicates an instrument that is a performance Index (not investable directly) */
	Index: 'Index', //600,
	/*** Indicates an instrument that is a Money Market instrument */
	MoneyMarket: 'MoneyMarket', //700,
	/*** Indicates an instrument that is specifically a Treasury Bill */
	TBills: 'TBills', //800,
} as const;

export type EQInstrumentClassEnum = typeof EQInstrumentClassEnum[keyof typeof EQInstrumentClassEnum];

export interface IClassTypePair {
	ClassName: string;
	TypeNames: string[];
}

export interface IClassTypeMapping {
	[key: string]: IClassTypePair;
}

export const EQInstrumentTypeByClass: IClassTypeMapping = {
	Bonds: {
		ClassName: 'Bonds',
		TypeNames: ['BOND_CORP', 'BOND_NAT', 'BOND_SUBNAT']
	},
	Commodity: {
		ClassName: 'Commodity',
		TypeNames: ['CMDTY_PRODUCE', 'CMDTY_RESOURCE']
	},
	Currency: {
		ClassName: 'Currency',
		TypeNames: ['CURRENCY']
	},
	Equity: {
		ClassName: 'Equity',
		TypeNames: ['EQUITY', 'FML', 'GDR', 'RIGHTS', 'ETF_FOREIGN', 'ETF_LOCAL']
	},
	Fund: {
		ClassName: 'Fund',
		TypeNames: ['FUND']
	},
	Index: {
		ClassName: 'Index',
		TypeNames: ['INDEX']
	},
};


/*** This enum indicates the type of an investment instrument */
export const EQInstrumentTypeEnum = {
	//NONE: 'NONE', //0,
	/*** Indicates an instrument that is a Corporate Bond */
	BOND_CORP: 'BOND_CORP', //101,
	/*** Indicates an instrument that is a National Bond (issued by the Federal Government) */
	BOND_NAT: 'BOND_NAT', //102,
	/*** Indicates an instrument that is a Sub-National Bond (issued by a State/Municipal Government) */
	BOND_SUBNAT: 'BOND_SUBNAT', //103,
	/*** Indicates a Commodity instrument that is a produce */
	CMDTY_PRODUCE: 'CMDTY_PRODUCE', //201,
	/*** Indicates a Commodity instrument that is a primary resource */
	CMDTY_RESOURCE: 'CMDTY_RESOURCE', //202,
	/*** Indicates Currency as a tradeable instrument */
	CURRENCY: 'CURRENCY', //300,
	/*** Indicates an instrument that is Equity */
	EQUITY: 'EQUITY', //401,
	/*** Indicates an instrument that is a Fund Memorandum Listing */
	FML: 'FML', //403,
	/*** Indicates an instrument that is a Global Depository Receipt */
	GDR: 'GDR', //404,
	/*** Indicates an instrument that represents tradeable Rights Issue */
	RIGHTS: 'RIGHTS', //405,
	/*** Indicates a foreign ETF */
	ETF_FOREIGN: 'ETF_FOREIGN', //411,
	/*** Indicates a local ETF */
	ETF_LOCAL: 'ETF_LOCAL', //412,
	/*** Indicates an instrument that is a Fund */
	FUND: 'FUND', //501,
	/*** Indicates an instrument that tracks performance across sectors/categories of stock */
	INDEX: 'INDEX', //600
};

export type EQInstrumentTypeEnum = typeof EQInstrumentTypeEnum[keyof typeof EQInstrumentTypeEnum];


export const FundOrderStating = {
	QTY: 'QTY',
	VAL: 'VAL',
};

export type FundOrderStating = typeof FundOrderStating[keyof typeof FundOrderStating];


export const HoldingCorpActionClosure = {
	Discarded: 'Discarded', //-2,
	Pending: 'Pending', //-1,
	CertFullReceipt: 'CertFullReceipt', //0,
	EFullReceipt: 'EFullReceipt', //1
};

export type HoldingCorpActionClosure = typeof HoldingCorpActionClosure[keyof typeof HoldingCorpActionClosure];


export const HoldingMovtClosure = {
	/*** Indicates that a HoldingMovt transaction is a pending request coming from the client. */
	PendingRequest: 'PendingRequest', //-10,
	/*** Indicates that a HoldingMovt transaction has failed. */
	Failed: 'Failed', //-3,
	/*** Indicates that a HoldingMovt transaction has been cancelled. */
	Cancelled: 'Cancelled', //-2,
	/*** Indicates that a HoldingMovt transaction is pending and can still be modified. */
	Pending: 'Pending', //-1,
	/*** Indicates that a HoldingMovt transaction is undergoing processing, reviews and approvals. */
	Processing: 'Processing', //0,
	/*** Indicates that a HoldingMovt transaction has been posted. */
	Posted: 'Posted', //2,
	/*** Indicates that a HoldingMovt transaction has been closed. */
	Closed: 'Closed', //10,
};

export type HoldingMovtClosure = typeof HoldingMovtClosure[keyof typeof HoldingMovtClosure];


export const HoldingPryIssueClosure = {
	Discarded: 'Discarded', //-2,
	Pending: 'Pending', //-1,
	Processed: 'Processed', //0,
	Remitted: 'Remitted', //1
	Alloted: 'Alloted', //2
};

export type HoldingPryIssueClosure = typeof HoldingPryIssueClosure[keyof typeof HoldingPryIssueClosure];


export const IndexWeighting = {
	PRICE: 'PRICE', //1,
	QUANTITY: 'QUANTITY', //2,
	VALUE: 'VALUE', //3,
};

export type IndexWeighting = typeof IndexWeighting[keyof typeof IndexWeighting];


/*** An enum showing the various types of Capital Market Operators */
export const MarketOperatorClass = {
	/***  */
	NONE: 'NONE', //0,
	/*** Indicates an Asset Management firm */
	AssetMgt: 'AssetMgt', //1,
	/*** Indicates a Brokerage firm */
	Brokerage: 'Brokerage', //2, //2
	/*** Indicates a Clearing House */
	ClearingHouse: 'ClearingHouse', //4, //2
	/*** Indicates a Custodian */
	Custodian: 'Custodian', //8, //3,4
	/*** Indicates an Exchange */
	Exchange: 'Exchange', //16, //4,8
	/*** Indicates a firm licensed to provide Financial Advisory services */
	FinancialAdviser: 'FinancialAdviser', //32, //4,8
	/*** Indicates an Issuing House */
	IssuingHouse: 'IssuingHouse', //64, //4,8
	/*** Indicates an Exchange */
	LendingAgent: 'LendingAgent', //128, //5,16
	/*** Indicates a Registrar firm */
	Registrar: 'Registrar', //256, //6,32
	/*** Indicates the Securities and Exchange Commission */
	SEC: 'SEC', //512, //7,64
	/*** Indicates a Trustee */
	Trustee: 'Trustee', //1024, //128
	/*** Indicates a Primary Dealer in Money Market */
	PDMM: 'PDMM', //2048,
};

export type MarketOperatorClass = typeof MarketOperatorClass[keyof typeof MarketOperatorClass];


/*** An enum showing the various states of an EQMovtTransfer */
export const MovtClosure = {
	/*** Indicates a difference between the reference transaction in Griffin and the CSD upload. */
	QuantityDiffers: 'QuantityDiffers', //-5,
	/*** Indicates Stock specified in the transfer record is missing from Griffin. */
	InstrNotFound: 'InstrNotFound', //-4,
	/*** Indicates AccountNo specified in the transfer record is missing from Griffin. */
	AcctNotFound: 'AcctNotFound', //-3,
	/*** Indicates the transfer has no corresponding transaction pre-recorded in Griffin. */
	TxnNotFound: 'TxnNotFound', //-2,
	/*** Indicates the transfer cannot be fully matched with an existing transaction using all parameters specified. */
	TxnNotMatched: 'TxnNotMatched', //-1,
	/*** Indicates a transfer that has been fully matched to an existing transaction. */
	TxnFound: 'TxnFound', //0,
	/*** Indicates that a transfer that has been processed. */
	TxnProcessed: 'TxnProcessed', //1,
};

export type MovtClosure = typeof MovtClosure[keyof typeof MovtClosure];


/*** An enum indicating the various types of MovtTransfer */
export const MovtType = {
	/*** Indicates a difference between the reference transaction in Griffin and the CSD upload. */
	CERT: 'CERT', //,
	/*** Indicates a transfer into an account. */
	XFIN: 'XFIN', //,
	/*** Indicates a transfer out of an account. */
	XFOUT: 'XFOUT', //,
};

export type MovtType = typeof MovtType[keyof typeof MovtType];


/*** An enum indicating the various ways of specifying life time of Orders */
export const OrderLifeEnum = {
	GoodForToday: 'GoodForToday', //0,
	GoodForDays: 'GoodForDays', //1,
	GoodTillCancelled: 'GoodTillCancelled', //2,
	GoodForClose: 'GoodForClose', //3,
	GoodForOpen: 'GoodForOpen', //4,
	GoodTillDate: 'GoodTillDate', //5,
	GoodTillTime: 'GoodTillTime', //6,
	FillKill: 'FillKill', //7,
	ImmediateCancel: 'ImmediateCancel', //8,
};

export type OrderLifeEnum = typeof OrderLifeEnum[keyof typeof OrderLifeEnum];

	
export const OrderJobClosure = {
	Rejected: 'Rejected', //-1000,
	Cancelled: 'Cancelled', //-100,
	Replaced: 'Replaced', //-70,
	InvalidInstrument: 'InvalidInstrument', //-40,
	InvalidAccount: 'InvalidAccount', //-30,
	InsufficientHoldings: 'InsufficientHoldings', //-20,
	Expired: 'Expired', //-10,
	Suspended: 'Suspended', //-5,
	Pending: 'Pending', //-1,
	Indeterminate: 'Indeterminate', //0,
	Jobbed: 'Jobbed', //1,
	PriceLimitExceeded: 'PriceLimitExceeded', //2,
	RequestStatus: 'RequestStatus', //3,
	RequestTradeCapture: 'RequestTradeCapture', //4,
	SubmittedSystem: 'SubmittedSystem', //11,
	QueuedApproval: 'QueuedApproval', //12,
	QueuedRoute: 'QueuedRoute', //13,
	SubmittedManual: 'SubmittedManual', //14,
	RequestCancel: 'RequestCancel', //21,
	RequestCancelReplace: 'RequestCancelReplace', //22,
	RequestSuspend: 'RequestSuspend', //23,
	RequestUnsuspend: 'RequestUnsuspend', //24,
	CancelSubmitted: 'CancelSubmitted', //31,
	SuspendSubmitted: 'SuspendSubmitted', //32,
	UnsuspendSubmitted: 'UnsuspendSubmitted', //33,
	PartialFill: 'PartialFill', //51,
	Filled: 'Filled', //52,
	TradingEnded: 'TradingEnded', //53
};

export type OrderJobClosure = typeof OrderJobClosure[keyof typeof OrderJobClosure];


export const ResCompanyResultType = {
	Q1: 'Q1', //1,
	Q2: 'Q2', //2,
	Q3: 'Q3', //3,
	Q4: 'Q4', //4,
	Q5: 'Q5', //5,
	FULL: 'FULL', //10,
	MTH01: 'MTH01', //101,
	MTH02: 'MTH02', //102,
	MTH04: 'MTH04', //104,
	MTH05: 'MTH05', //105,
	MTH07: 'MTH07', //107,
	MTH08: 'MTH08', //108,
	MTH10: 'MTH10', //110,
	MTH11: 'MTH11', //111,
	MTH12: 'MTH12', //112,
	MTH13: 'MTH13', //113,
	MTH14: 'MTH14', //114,
	MTH15: 'MTH15', //15,
	MTH16: 'MTH16', //116,
	MTH17: 'MTH17', //117,
	MTH18: 'MTH18', //118
};

export type ResCompanyResultType = typeof ResCompanyResultType[keyof typeof ResCompanyResultType];


export const ResCoReportClassEnum = {
	CompanyProjection: 'CompanyProjection', //1,
	ResearchProjection: 'ResearchProjection', //2,
	UnauditedResult: 'UnauditedResult', //3,
	AuditedResult: 'AuditedResult', //4,
};

export type ResCoReportClassEnum = typeof ResCoReportClassEnum[keyof typeof ResCoReportClassEnum];


export const SecInstrumentState = {
	Discontinued: 'Discontinued', //-2,
	Pending: 'Pending', //-1,
	Active: 'Active', //0,
	Deactivated: 'Deactivated', //1
};

export type SecInstrumentState = typeof SecInstrumentState[keyof typeof SecInstrumentState];


export const SecListingType = {
	Unlisted: 'Unlisted', //0,
	NominalListing: 'NominalListing', //1,
	TradeListing: 'TradeListing', //2
};

export type SecListingType = typeof SecListingType[keyof typeof SecListingType];


export const SecOrderExecType = {
	NothingSpecial: 'NothingSpecial', //0,
	AllOrNone: 'AllOrNone', //1,
	//_IsBlock: '_IsBlock', //2,
	BestExecution: 'BestExecution', //4 | _IsBlock,
	OkToCross: 'OkToCross', //8,
	OverTheDay: 'OverTheDay', //16 | _IsBlock,
	PegToVWAP: 'PegToVWAP', //32 | _IsBlock,
};

export type SecOrderExecType = typeof SecOrderExecType[keyof typeof SecOrderExecType];


export const SecOrderLimitType = {
	Limit: 'Limit', //1,
	Market: 'Market', //2,
	MarketAtBest: 'MarketAtBest', //3,
};

export type SecOrderLimitType = typeof SecOrderLimitType[keyof typeof SecOrderLimitType];


export const SecOrderPriceType = {
	Percentage: 'Percentage', //1,
	PerUnit: 'PerUnit', //2,
	Spread: 'Spread', //6,
	Yield: 'Yield', //9,
};

export type SecOrderPriceType = typeof SecOrderPriceType[keyof typeof SecOrderPriceType];


export const SecOrderTrackingType = {
	None: 'None', //0,
	Bid: 'Bid', //1,
	Mid: 'Mid', //2,
	Offer: 'Offer', //3,
};

export type SecOrderTrackingType = typeof SecOrderTrackingType[keyof typeof SecOrderTrackingType];


export const SecTransactionType = {
	BUY: 'BUY', //0x41,
	CANCEL: 'CANCEL', //0x83,
	ISSUE: 'ISSUE', //0x87,
	SELL: 'SELL', //0x42
};

export type SecTransactionType = typeof SecTransactionType[keyof typeof SecTransactionType];


export const SecXferDirections = {
	XFIN: 'XFIN', //+1,
	XFOUT: 'XFOUT', //-1
};

export type SecXferDirections = typeof SecXferDirections[keyof typeof SecXferDirections];


export const SecXferType = {
	Custodian: 'Custodian', //1,
	DealingMember: 'DealingMember', //2,
	LendingAgent: 'LendingAgent', //3,
};

export type SecXferType = typeof SecXferType[keyof typeof SecXferType];


export const StpContractModes = {
	/**
	 * Deliver contract note for each executed deal/trade
	 * */
	SINGLE: 'SINGLE', //11,

	/**
	 * Indicates that the system should batch the delivery of contract notes by Order ie as soon as the Order is filled, killed or the trading session ends
	 * 
	 * @remarks 
	 * still aggregate by price
	 * */
	BATCHORDER: 'BATCHORDER', //21,

	/**
	 * Indicates that the system should batch the delivery of contract notes at the end of the trading day/session
	 * 
	 * @remarks 
	 * still aggregate by price
	 * */
	BATCHDAY: 'BATCHDAY', //22
};

export type StpContractModes = typeof StpContractModes[keyof typeof StpContractModes];


export const StpOrderModes = {
	/*** The order will be subjected to automated rule verification and fired off to the Exchange when/where appropriate */
	FIX_STP: 'FIX_STP', //11,

	/*** The order will be subjected to automated rule verification and queued for trader/broker review */
	BATCHED: 'BATCHED', //21,

	/*** The order will be subjected to automated rule verification and queued for routing (by head trader) to the appropriate trading desk */
	DEALER: 'DEALER', //22
};

export type StpOrderModes = typeof StpOrderModes[keyof typeof StpOrderModes];


export const TradeExclusionType = {
	AllowAll: 'AllowAll', //0,

	Complete: 'Complete', //2,

	ThresholdQuantity: 'ThresholdQuantity', //5

	ThresholdValue: 'ThresholdValue', //9
};

export type TradeExclusionType = typeof TradeExclusionType[keyof typeof TradeExclusionType];
