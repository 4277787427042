import { combineReducers } from 'redux';

import { toIsoDateString } from 'app-helpers/basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { DefNgNseExchangeId, EnumAllOptionValue, NullEntityId, oneYearAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-redux/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	OnboardBankAcctList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'OnboardBankAcctList',
		entityName: 'OnboardBankAcct',
		cmdUriTemplate: 'Onboard/Active/BankAccts',
		fetchUriTemplate: 'Onboard/Active/BankAccts',
		saveUriTemplate: 'Onboard/Active/BankAccts',
		filterValues: {
			searchText: '',
		},
	},

	OnboardDocumentList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'OnboardDocumentList',
		entityName: 'OnboardDocument',
		cmdUriTemplate: 'Onboard/Active/Documents',
		fetchUriTemplate: 'Onboard/Active/Documents',
		saveUriTemplate: 'Onboard/Active/Documents',
		filterValues: {
			searchText: '',
		},
	},

	OnboardRelationshipList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'OnboardRelationshipList',
		entityName: 'OnboardRelationship',
		fetchUriTemplate: 'Onboard/Active/Relationships',
		filterValues: {
			searchText: '',
		},
	},

	OnboardTaxResidencyList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'OnboardTaxResidencyList',
		entityName: 'OnboardTaxResidency',
		cmdUriTemplate: 'Onboard/Active/TaxResidencies',
		fetchUriTemplate: 'Onboard/Active/TaxResidencies',
		saveUriTemplate: 'Onboard/Active/TaxResidencies',
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	OnboardBioView: {
		...fluxViewDefaults,
		actionNamespace: 'OnboardBioView',
		entityName: 'Onboard',
		fetchUriTemplate: 'Onboard/Active/BioEmployer',
		fetchUriComposited: true,
		saveUriTemplate: 'Onboard/Active/BioEmployer',
	},

	OnboardEmployerNextOfKinView: {
		...fluxViewDefaults,
		actionNamespace: 'OnboardEmployerNextOfKinView',
		entityName: 'OnboardRelationship',
		fetchUriTemplate: 'Onboard/Active/EmployerNextOfKin',
		fetchUriComposited: true,
		saveUriTemplate: 'Onboard/Active/EmployerNextOfKin',
	},

	OnboardNextOfKinView: {
		...fluxViewDefaults,
		actionNamespace: 'OnboardNextOfKinView',
		entityName: 'OnboardRelationship',
		fetchUriTemplate: 'Onboard/Active/NextOfKin',
		fetchUriComposited: true,
		saveUriTemplate: 'Onboard/Active/NextOfKin',
	},
	
	OnboardInvestmentView: {
		...fluxViewDefaults,
		actionNamespace: 'OnboardInvestmentView',
		entityName: 'OnboardInvestment',
		fetchUriTemplate: 'Onboard/Active/Investment',
		fetchUriComposited: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
