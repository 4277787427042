/*** This enum indicates the various methods of Authentication the app supports */
export const AuthenticationType = {
	/*** This indicates authentication is carried out by the the app */
	APP: 'APP',
	/*** This indicates authentication is based on certificates */
	CERT: 'CERT',
	/*** This indicates authentication is carried out using the DB's mechanisms */
	DB: 'DB',
	/*** This indicates authentication against LDAP */
	LDAP: 'LDAP',
	/*** This indicates authentication is carried out by a 3rd party provider */
	PROV: 'PROV',
};

export type AuthenticationType = typeof AuthenticationType[keyof typeof AuthenticationType];


/*** This enum indicates the state of a system job */
export const JobClosure = {
    /*** This indicates that the job id disabled and will not be run */
    IsDisabled: 'IsDisabled', //-2,
    /*** This indicates that a job is currently active and will be run on schedule */
    IsActive: 'IsActive', //-1,
};

export type JobClosure = typeof JobClosure[keyof typeof JobClosure];


/*** This enum indicates the state of a metaUser */
export const metaUserClosure = {
    /*** This indicates that a user has been deleted */
    IsDeleted: 'IsDeleted', //-100,
    /*** This indicates that a user is locked */
    IsLocked: 'IsLocked', //-2,
    /*** This indicates that a user is currently active */
    IsActive: 'IsActive', //-1,
};

export type metaUserClosure = typeof metaUserClosure[keyof typeof metaUserClosure];


/*** Well known metaUser names that have significance to GriffinERP */
export const metaUserKnownNames = {
    /*** The account used for setting up this instance of GriffinERP */
    griffinsetup: 'griffinsetup', //1,
    /*** The core/default admin account */
    pxadmin: 'pxadmin', //2,
    /*** The core/default test account */
    pxtest: 'pxtest', //3,

    /*** The default guest account to be used for auditors and other such individuals */
    griffinguest: 'griffinguest', //5,

    /*** The default 3rd party app integration account */
    grifintegrator: 'grifintegrator', //11,
    /*** The default 3rd party app integration admininistrator/manager account */
    grifintegratoradmin: 'grifintegratoradmin', //12,

    /*** The system daemon user */
    eiMarshal: 'eiMarshal', //1127
};

export type metaUserKnownNames = typeof metaUserKnownNames[keyof typeof metaUserKnownNames];


/**Enum indicating the kind of assignee for a Workflow Step*/
export const PxWorkflowAssigneeType = {
    //Determine: 'Determine', //-1,
    /**Indicates a workflow step is assigned to an External party*/
	External: 'External', //0,
    /**Indicates a workflow step is assigned to the machine/system*/
	Machine: 'Machine', //1,
    /**Indicates the assignee to a workflow step is a specific staff*/
	Staff: 'Staff', //2,
    /**Indicates the assignee to a workflow step is a member of an OrgUnit*/
	Unit: 'Unit', //4,
    /**Indicates the assignee to a workflow step is a member of a security role*/
	SecRole: 'SecRole', //8,
    /**Indicates the assignee to a workflow step has an OrgRole*/
	OrgRole: 'OrgRole', //16,
    /**Indicates the assignee to a workflow step is a specific staff or a member of a security role*/
	SecRoleStaff: 'SecRoleStaff', //SecRole | Staff,
    /**Indicates the assignee to a workflow step is a member of an OrgUnit or a member of a security role*/
	SecRoleUnit: 'SecRoleUnit', //SecRole | Unit,
    /**Indicates the assignee to a workflow step has an OrgRole or is a member of a security role*/
	SecRoleOrgRole: 'SecRoleOrgRole', //SecRole | OrgRole,
}

export type PxWorkflowAssigneeType = typeof PxWorkflowAssigneeType[keyof typeof PxWorkflowAssigneeType];


/**Enum indicating possible way of filtering workflow items for user viewing. Do not assign to any Entity field.*/
export const PxWorkflowAssignmentFilter = {
    /**Filter items exclusively assigned to the current user*/
    EXPLICITLY_ME: 'EXPLICITLY_ME', //1,
    /**Filter items assigned to a security role of the current user*/
	MY_SECROLE: 'MY_SECROLE', //2,
    /**Filter items assigned to the OrgUnit of the current user*/
	MY_UNIT: 'MY_UNIT', //3,
    /**Filter items assigned to the division (root OrgUnit) of the current user*/
	MY_DIVISION: 'MY_DIVISION', //4,
    /**Filter items assigned to me, my SecRole, my OrgRole, my OrgUnit, my division*/
	POSSIBLY_ME: 'POSSIBLY_ME', //5,
    /**Filter items assigned to my SecRole, my OrgRole, my OrgUnit, my division but not directly to me*/
	NOT_DIRECTLY_ME: 'NOT_DIRECTLY_ME', //6,
    /**Filter for items that are not assigned to me in any way*/
	NOT_ME_AT_ALL: 'NOT_ME_AT_ALL', //7,
    /**Filter for items that are not assigned to a human (ie Machine step)*/
	NOT_HUMAN: 'NOT_HUMAN', //8,
    /**Filter items assigned to a given OrgRole of the current user*/
	MY_ORGROLE: 'MY_ORGROLE', //9,
}

export type PxWorkflowAssignmentFilter = typeof PxWorkflowAssignmentFilter[keyof typeof PxWorkflowAssignmentFilter];


export const PxWorkflowStateFilter = {
	ACTIVE: 'ACTIVE',
	COMPLETED: 'COMPLETED',
	NEGLECTED: 'NEGLECTED',
	NOTACTIVE: 'NOTACTIVE',
};

export type PxWorkflowStateFilter = typeof PxWorkflowStateFilter[keyof typeof PxWorkflowStateFilter];


export const PxWorkflowStepType = {
	_Machine: '_Machine', //0,
	//_Approval: '__', //1,
	//_Edit: '__', //2,
	//_Notes: '__', //4,
	//_Submit: '__', //8,
	//_ValueCollect: '__', //16,
	//_View: '__', //32,

	Machine: 'Machine', //_Machine,
	ViewApproval: 'ViewApproval', //_View | _Approval,
	ViewEditApproval: 'ViewEditApproval', //_View | _Approval | _Edit,
	ViewEditSubmit: 'ViewEditSubmit', //_View | _Submit | _Edit,
	ViewNotesSubmit: 'ViewNotesSubmit', //_View | _Notes | _Submit,
	ViewValueCollect: 'ViewValueCollect', //_View | _ValueCollect,
}

export type PxWorkflowStepType = typeof PxWorkflowStepType[keyof typeof PxWorkflowStepType];


export const PxWorkflowType = {
	//_DrivenEntity: '_DrivenEntity', //1,
	//_DrivenWF: '_DrivenWF', //2,
	//_Dynamic: '_Dynamic', //4,
	//_Static: '_Static', //8,
	//_Approval: '_Approval', //16,
	//_Workflow: '_Workflow', //32,
	//_LifeTime: '_LifeTime', //64,


	DynamicEntityApproval: 'DynamicEntityApproval', //_Dynamic | _DrivenEntity | _Approval,
	DynamicEntityWorkflow: 'DynamicEntityWorkflow', //_Dynamic | _DrivenEntity | _Workflow, //37
	DynamicWFApproval: 'DynamicWFApproval', //_Dynamic | _DrivenWF | _Approval,
	DynamicWFWorkflow: 'DynamicWFWorkflow', //_Dynamic | _DrivenWF | _Workflow,

	StaticEntityApproval: 'StaticEntityApproval', //_Static | _DrivenEntity | _Approval,
	StaticEntityWorkflow: 'StaticEntityWorkflow', //_Static | _DrivenEntity | _Workflow, //41
	//StaticWFApproval: 'StaticWFApproval', //Static | DrivenWF | Approval,
	//StaticWFWorkflow: 'StaticWFWorkflow', //Static | DrivenWF | Workflow,
}

export type PxWorkflowType = typeof PxWorkflowType[keyof typeof PxWorkflowType];


export const PxWorkflowState = {
	Discarded: 'Discarded', //-3,
	Suspended: 'Suspended', //-2,
	Pending: 'Pending', //-1,
	Received: 'Received', //0,
	Assigned: 'Assigned', //1,
	Closed: 'Closed', //2
}

export type PxWorkflowState = typeof PxWorkflowState[keyof typeof PxWorkflowState];


export const UserModeEnum = {
	Disabled: 'Disabled', //-10,
	Onboarding: 'Onboarding', //-2,
	Reviewing: 'Reviewing', //-1,
	SteadyState: 'SteadyState', //0,
}

export type UserModeEnum = typeof UserModeEnum[keyof typeof UserModeEnum];

