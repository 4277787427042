import { IRouteMenuData } from "utils/IRouteData";
import { Navigate, Route } from "react-router-dom";
import { $isNull } from "app-helpers/basicFunctions";

const RouteMapper = (props: { routeData: IRouteMenuData, routeKey: number }) => {
	const { routeData, routeKey } = props;

	if (routeData.navlabel) {
		return null;
	} else if (routeData.collapse) {
		const children = routeData.child || routeData.subchild;

		return children.map(
			(prop2, key2) => {
				return RouteMapper({ routeData: prop2, routeKey: key2});
			});
	} else if (routeData.redirect) {
		return (
			<Route key={routeData.path} path={routeData.path} element={<Navigate replace to={routeData.pathTo} />} />
		);
	} else {
		// if (!$isNull(routeData.template))
		// 	console.log('routeData.template - ', routeData.template);
		
		return (
			//<Route key={routeKey} path={routeData.path} element={routeData.component} />
			<Route key={routeKey} path={routeData.template || routeData.path} element={<routeData.component />} />
			// <Route key={routeKey} path={routeData.path}>
			// 	{routeProps => (<routeData.component {...routeProps} />)}
			// </Route>
		);
	}
};

export default RouteMapper;
