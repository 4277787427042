import { combineReducers } from 'redux';

import { toIsoDateString } from 'app-helpers/basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { DefNgNseExchangeId, EnumAllOptionValue, NullEntityId, oneYearAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-redux/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists: IListComponentControlList = {

	WillList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'WillList',
		entityName: 'OnboardWill',
		cmdUriTemplate: 'Will',
		fetchUriTemplate: 'Will',
		saveUriTemplate: 'Will',
		filterValues: {
			searchText: '',
		},
	},
	BeneficiaryList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'BeneficiaryList',
		entityName: 'OnboardWillBeneficiary',
		cmdUriTemplate: 'OnboardWill/Active/Beneficiary',
		fetchUriTemplate: 'OnboardWill/Active/Beneficiary',
		saveUriTemplate: 'OnboardWill/Active/Beneficiary',
		filterValues: {
			searchText: '',
		},
	},

	PersonalPropertyList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'PersonalPropertyList',
		entityName: 'OnboardWillPersonalProperty',
		cmdUriTemplate: 'OnboardWill/Active/PersonalProperty',
		fetchUriTemplate: 'OnboardWill/Active/PersonalProperty',
		saveUriTemplate: 'OnboardWill/Active/PersonalProperty',
		filterValues: {
			searchText: '',
		},
	},

	RealPropertyList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'RealPropertyList',
		entityName: 'OnboardWillRealProperty',
		cmdUriTemplate: 'OnboardWill/Active/RealProperty',
		fetchUriTemplate: 'OnboardWill/Active/RealProperty',
		saveUriTemplate: 'OnboardWill/Active/RealProperty',
		filterValues: {
			searchText: '',
		},
	},

	BankAccountList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'BankAccountList',
		entityName: 'OnboardWillBankAccount',
		cmdUriTemplate: 'OnboardWill/Active/BankAcct',
		fetchUriTemplate: 'OnboardWill/Active/BankAcct',
		saveUriTemplate: 'OnboardWill/Active/BankAcct',
		filterValues: {
			searchText: '',
		},
	},

	InvestmentAccountList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'InvestmentAccountList',
		entityName: 'OnboardWillInvestmentAccount',
		cmdUriTemplate: 'OnboardWill/Active/InvestmentAcct',
		fetchUriTemplate: 'OnboardWill/Active/InvestmentAcct',
		saveUriTemplate: 'OnboardWill/Active/InvestmentAcct',
		filterValues: {
			searchText: '',
		},
	},


	SavingsAccountList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'SavingsAccountList',
		entityName: 'OnboardWillSavingsAccount',
		cmdUriTemplate: 'OnboardWill/Active/SavingsAcct',
		fetchUriTemplate: 'OnboardWill/Active/SavingsAcct',
		saveUriTemplate: 'OnboardWill/Active/SavingsAcct',
		filterValues: {
			searchText: '',
		},
	},

};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction): IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews: IViewComponentControlList = {
	ClientInfoView: {
		...fluxViewDefaults,
		actionNamespace: 'ClientInfoView',
		entityName: 'OnboardWill',
		fetchUriTemplate: 'OnboardWill/Active',
		fetchUriComposited: true,
		saveUriTemplate: 'OnboardWill/Active',
	},

	LodgementSubmissionView: {
		...fluxViewDefaults,
		actionNamespace: 'LodgementSubmissionView',
		entityName: 'OnboardWill',
		fetchUriTemplate: 'OnboardWill/Active',
		fetchUriComposited: true,
		saveUriTemplate: 'OnboardWill/Active/Attest',
	},

};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction): IViewComponentControlList {
	return masterViewReducer(state, action);
}


function settingsReducer(state: IViewComponentControlList = {}, action: IFluxAction):  {completed: boolean} {
	return {
		'completed': false
	};
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
	// settings: settingsReducer
});
