import { FunctionComponent, lazy, Suspense } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import Spinner from "app-framework/spinner/Spinner";

const FullLayout = lazy(() => import("app-layouts/FullLayout"));

const Login = lazy(() => import("app-views/PageUnauthenticated"));

import GeneralRoutes from "app-layouts/GeneralRoutes";
import RouteMapper from "./RouteMapper";

export const LogonUnauthenticatedRouteMap: FunctionComponent = () => {
	return (
		<Routes>
			<Route index element={<Navigate to="/home" />} />

			{GeneralRoutes.map((prop, key) => RouteMapper({ routeData: prop, routeKey: key }))}
	
			{/*For this catch-all define a page not found*/}
			{/* <Route path="*" element={<Navigate to='/home'  />} /> */}
		</Routes>
	);
};
