import { combineReducers } from "redux";

import { globalsReducer } from './globalsReducer';
import { sessionReducer } from './sessionReducer';

import willdraft from 'app-modules/willdraft/_reducers';
import vgst from 'app-modules/vgst/_reducers';
import onboard from 'app-modules/onboard/_reducers';

import settings from "app-redux/settings/Reducer";

const rootReducer = combineReducers({
	globals: globalsReducer,
	session: sessionReducer,

	settings,

	willdraft,
	vgst,
	onboard,
	
});

export default rootReducer;
