import { lazy } from "react";

import { IRouteMenuData } from "utils/IRouteData";

import WillDraftRoutes from "app-modules/willdraft/_routemenu";

import VGSTRoutes from "app-modules/vgst/_routemenu";

const PortfolioSummary = lazy(() => import("app-modules/dashboards/PortfolioSummary"));

// ['a', 'sffd', 'dsdsd']
// let a:string[] = [2];

const ThemeRoutes: IRouteMenuData[] = [
	WillDraftRoutes,
	// VetpRoutes,
	VGSTRoutes,
	{
		path: "/dashboards/portfoliosummary",
		name: "Dashboard",
		// mini: "B",
		// icon: dashboard,
		component: PortfolioSummary,
	
	},

	// {
	// 	path: "*",
	// 	pathTo: "/dashboards/headsupdisplay",
	// 	name: "Dashboard",
	// 	redirect: true,
	// },
];

export default ThemeRoutes;
