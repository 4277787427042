import { compose } from 'redux';

import { IPaystackPop } from 'utils/PaystackModel';
import { IThemeProperties } from 'utils/IThemeProperties';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
		grifAdalConfig?: any;
		PaystackPop: IPaystackPop;
		payConfig?: any;
		themeProperties: IThemeProperties;
	}
}

const windowCopy = window;

export { windowCopy as window };
