import { IFluxAction } from 'app-model/IFluxAction';

const initialSessionState: any = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false
};

export function sessionReducer(state: any = initialSessionState, action: IFluxAction) {
	//console.log('sessionReducer called');

	if (action.type === 'setSession') {
		return {
            ...state,

            ...action.payload
        };
	}

	if (action.type === 'LOGIN') {
		return state;
	}

	if (action.type === 'LOGOUT') {
		return state;
	}

	return state;
};
