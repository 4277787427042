import { lazy } from "react";
import vgstIndividual from './individual/_routemenu';
const VgstMaster = lazy(() => import("app-modules/vgst/VgstMaster"));

const moduleRoutes = 	{
	vgstIndividual,
	//collapse: true,
	// template: "/wills/:id/view/*",
	//path: "/willdraft/999999999/view/*",
	path: "/vgsthome/*",
	name: "VGST",
	state: "vgstpages",
	icon: "codepen",
	component: VgstMaster,
};

export default moduleRoutes;
