import { lazy } from "react";
import { IRouteMenuData } from "utils/IRouteData";

const PageAbout = lazy(() => import("app-views/PageAbout"));
// const PageContactUs = lazy(() => import("app-views/PageContactUs"));
const PageIndex = lazy(() => import("app-views/PageIndex"));
// const PageServices = lazy(() => import("app-views/PageServices"));
const VetphomePage = lazy(() => import("app-views/VetphomePage"));
const PageVgst  = lazy(() => import("app-views/PageVgst"));

const PageWillsDrafting  = lazy(() => import("app-views/PageWillsDrafting"));
const PagePrivateTrust  = lazy(() => import("app-views/PagePrivateTrust"));
const PageShareScheme  = lazy(() => import("app-views/PageShareScheme"));
const Servicesvetp  = lazy(() => import("app-views/Servicesvetp"));
const Serviceswill  = lazy(() => import("app-views/Serviceswill"));
const UserSignin = lazy(() => import("app-views/Signin"));
const StaffShareScheme = lazy(() => import("app-views/StaffshareScheme"));


const GeneralRoutes: IRouteMenuData[] = [
	{
		path: "/home",
		name: "Home",
		component: PageIndex,
	},
	{
		path: "/about",
		name: "about",
		component: PageAbout,
	},
	{
		path: "/vetphome",
		name: "vetphome",
		component: VetphomePage,
	},
	{
		path: "/vgst",
		name: "vgst",
		component: PageVgst,
	},

	{
		path: "/willDraft",
		name: "willDraft",
		component: PageWillsDrafting,
	},
	{
		path: "/privatetrust",
		name: "privatetrust",
		component: PagePrivateTrust,
	},
	{
		path: "/sharescheme",
		name: "sharescheme",
		component: PageShareScheme,
	},

	{
		path: "/services-vetp",
		name: "services-vetp",
		component: Servicesvetp,
	},
	{
		path: "/services-will",
		name: "services-will",
		component: Serviceswill,
	},
	{
		path: "/signIn",
		name: "signIn",
		component: UserSignin,
	},
	{
		path: "/staffShareScheme",
		name: "staffShareScheme",
		component: StaffShareScheme,
	},
];

export default GeneralRoutes;
