import { SysActions } from "app-model/SysActions";
import { $isNullOrEnumAll, $isNullOrNullEntityId, NullEntityId } from "app-model/ModelConstants";

import { $isNull, $isNullOrEmpty, $nullCoalesce, $replaceAll } from './basicFunctions';

import { adalApiFetch, adalGraphFetch, endpoints } from "adalConfig";

export const substituteFilterValues = (uriTemplate: string, filterValues: any) => {
	return Object.entries(filterValues)
		.reduce(
			(pv, cv) => {
				const cvName = String(cv[0]);
				const cvValue = String(cv[1]);

				const cvQuote = (cvName.startsWith('enumParam') || cvName === 'searchText' || cvName === 'SearchText') ? '\'' : '';

				const cvPlaceholder = '[$][{]' + cvName + '[}]';  //we had to escape these 3 xters because we are using RegEx under the hood - $ { }

				const cvValueToInsert = 
					!($isNullOrEnumAll(cvValue) || $isNullOrNullEntityId(cvValue)) //(all)
					? (cvQuote + String(cvValue) + cvQuote)
					: 'null';

				//console.log('cvName - ', cvName);
				//console.log('cvValue - ', cvValue);
				//console.log('cvQuote - ', cvQuote);

				return $replaceAll(pv, cvPlaceholder, cvValueToInsert);
			},
			uriTemplate
		);
};

export const appendQueryFromFilterValues = (uriResource: string, filterValues: any) => {
	const queryString = Object.entries(filterValues)
		.reduce(
			(pv, cv) => {
				const rawCvName = String(cv[0]);
				const cvValue = String(cv[1]);

				if ($isNullOrEmpty(cvValue) || $isNullOrEnumAll(cvValue) || $isNullOrNullEntityId(cvValue))
					return pv;

				let cvName = rawCvName;

				if (rawCvName.startsWith('enumParam')) {
					cvName = rawCvName.substring(9, 10).toLowerCase() + rawCvName.substring(10);
				}

				return pv + ($isNullOrEmpty(pv) ? '' : '&') + `${cvName}=${cvValue}`;
			},
			''
		);

	return uriResource + ($isNullOrEmpty(queryString) ? '' : ((uriResource.indexOf('?') > -1 ? '&' : '?') + queryString));
};

/**
 * The default page size assigned for Global list queries
 */
export const fullSubDataPageSize = 5000;

/**
 * A helper function that builds up a filter clause for an OData URL
 * 
 * @param searchText the text to be searched in the designated string fields of an entity
 * @param searchFields the string/xter fields that will be searched for the [[searchText]]
 * @param filterExpressions some prebuilt filter expressions to be appended to the $filter clause
 * @returns returns a built up OData filter clause
 */
export function makeOdataFilterClause(searchText: string, searchFields: string[], filterExpressions: string[] = []) : string {
	let fetchUrl: string = '';

	let priorClause = false;

	if (!$isNull(filterExpressions) && filterExpressions.length > 0) {
		fetchUrl = filterExpressions.join(' and ');

		priorClause = true;
	}

	if (!$isNullOrEmpty(searchText) && !$isNull(searchFields) && searchFields.length > 0) {
		const searchQuery = '('
			+ searchFields
				.map(fd => `contains(${fd},'${searchText}')`)
				.join(' or ')
			+ ')';

		fetchUrl = fetchUrl + (priorClause ? ' and ' : '') + searchQuery;

		priorClause = true;
	}

	if (!$isNullOrEmpty(fetchUrl))
		fetchUrl = '&$filter=' + fetchUrl;

	return fetchUrl;
}

/**
 * A helper function that adds the usual clauses to an OData URL
 * 
 * @param fetchUrl a prebuilt OData URL
 * @param priorClause a boolean indicating whether [[fetchUrl]] already contains an OData query/parameter/clause
 * @param selectFields the entity fields to select for return
 * @param expandProps the navigation properties the OData endpoint should expand before returning results
 * @returns returns an intermediate OData URL and query for further processing by calling functions
 */
export function addOdataSelectExpandClauses(fetchUrl: string, priorClause: boolean, selectFields: string[], expandProps: string[]) : string {
	if ($isNullOrEmpty(fetchUrl)) 
		throw new Error('fetchUrl must be specified.');
	
	if (!$isNull(selectFields) && selectFields.length > 0) {
		const selectQuery = '$select=' + selectFields.join(',');

		fetchUrl = fetchUrl + (priorClause ? '&' : '?') + selectQuery;

		priorClause = true;
	}

	if (!$isNull(expandProps) && expandProps.length > 0) {
		const expandQuery = '$expand=' + expandProps.join(',');

		fetchUrl = fetchUrl + (priorClause ? '&' : '?') + expandQuery;

		priorClause = true;
	}

	return fetchUrl;
}

/**
 * A helper function that builds up a complete OData request URL
 * 
 * @param resource the resource that returns a list
 * @returns returns a completely built List OData URL and query
 */
export function getApiOdataSimpleUrl(resource: string): string {
	if ($isNullOrEmpty(resource))
		throw new Error('resource must be specified.');

	let fetchUrl = `${endpoints.queryEndpoint}/${resource}`;

	return fetchUrl;
}

/**
 * A helper function that builds up a complete OData request URL
 * 
 * @param resource the resource that returns a list
 * @returns returns a completely built List OData URL and query
 */
export function getApiRestSimpleUrl(resource: string): string {
	if ($isNullOrEmpty(resource))
		throw new Error('resource must be specified.');

	let fetchUrl = `${endpoints.queryEndpoint}/${resource}`;

	return fetchUrl;
}

/**
 * A helper function that builds up a complete OData request URL
 * 
 * @remarks
 * This function is called by [[fetchApiOdataListData]]
 * 
 * @param resource the resource that returns a list
 * @param orderBy the fields to use to order the returned list
 * @param searchText the text to be searched in the designated string fields of an entity
 * @param searchFields the string/xter fields that will be searched for the [[searchText]]
 * @param pageToFetch since all results are paged, this indicates the page to be fetch; it works with [[sizePage]]
 * @param sizePage the size of the page to be returned; it goes hand in hand with [[pageToFetch]]
 * @param selectFields the entity fields to select for return
 * @param expandProps the navigation properties the OData endpoint should expand before returning results
 * @param filterExpressions the set of expressions for filtering the returned data
 * @returns returns a completely built List OData URL and query
 */
 export function getApiOdataListUrl(resource: string, orderBy: string, searchText: string, searchFields: string[], pageToFetch: number, sizePage: number, selectFields: string[] = [], expandProps: string[] = [], filterExpressions: string[] = []): string {
    if ($isNullOrEmpty(resource)) 
        throw new Error('resource must be specified.');
    
    if (pageToFetch < 1) 
        throw new Error('pageToFetch cannot be less than 1');
    
    if (sizePage < 1) 
        throw new Error('sizePage cannot be less than 1');
    
	if ($isNullOrEmpty(orderBy))
		orderBy = null;

	//orderBy = $nullCoalesce(orderBy, 'id asc');
	pageToFetch = $nullCoalesce(pageToFetch, 1);

	let fetchUrl = `${endpoints.queryEndpoint}/${resource}?$count=true`;

	//orderby
	if (!$isNullOrEmpty(orderBy)) {
		fetchUrl = fetchUrl + `&$orderby=${orderBy}`;
	}

	//skip, top
	fetchUrl = fetchUrl + `&$skip=${(pageToFetch - 1) * sizePage}&$top=${sizePage}`;

	//select, expand
	fetchUrl = addOdataSelectExpandClauses(fetchUrl, true, selectFields, expandProps);

	// search
	fetchUrl = fetchUrl + makeOdataFilterClause(searchText, searchFields, filterExpressions);

	return fetchUrl;
}

/**
 * A helper function that builds up a complete OData request URL
 * 
 * @remarks
 * This function is called by [[fetchApiRestListData]]
 * 
 * @param resource the resource that returns a list
 * @param orderBy the fields to use to order the returned list
 * @param searchText the text to be searched in the designated string fields of an entity
 * @param pageToFetch since all results are paged, this indicates the page to be fetch; it works with [[sizePage]]
 * @param sizePage the size of the page to be returned; it goes hand in hand with [[pageToFetch]]
 * @returns returns a completely built List OData URL and query
 */
export function getApiRestListUrl(resource: string, searchText: string, pageToFetch: number, sizePage: number): string {
	if ($isNullOrEmpty(resource)) 
		throw new Error('resource must be specified.');
	
	if (pageToFetch < 1) 
		throw new Error('pageToFetch cannot be less than 1');
	
	if (sizePage < 1) 
		throw new Error('sizePage cannot be less than 1');

	//orderBy = $nullCoalesce(orderBy, 'id asc');
	pageToFetch = $nullCoalesce(pageToFetch, 1);

	let fetchUrl = `${endpoints.queryEndpoint}/${resource}${resource.indexOf('?') > -1 ? '&' : '?'}pageNumber=${pageToFetch}&pageSize=${sizePage}`;

	if (!$isNullOrEmpty(searchText)) 
		fetchUrl = fetchUrl + `&SearchText=${encodeURI(searchText)}`;

	return fetchUrl;
}


/**
 * An interface describing the fetchApiRestListData return data.
 */
export interface IApiListReturn {
	totalCount: number;
	sizePage: number;
	totalPages: number;
	currentPage: number;
	listData: any[];
	error: boolean;
	errorInfo?: any;
}

/**
 * A helper function that builds up a complete OData request URL
 * 
 * @param fetchUrl the fully composed URL representing the resource to fetch from the API endpoint
 * @param pageToFetch since all results are paged, this indicates the page to be fetch; it works with [[sizePage]]
 * @param sizePage the size of the page to be returned; it goes hand in hand with [[pageToFetch]]
 * @returns returns List data fetched from the API OData endpoint
 */
 export async function fetchApiOdataListData(fetchUrl: string, pageToFetch: number, sizePage: number): Promise<IApiListReturn> {
	try {
        let fetchOptions = { 
            method: 'GET', 
            //mode: 'no-cors' 
			headers: {
				'Accept': 'application/json',
			},
        };

		//console.log(`fetchApiOdataListData: page - ${pageToFetch}, sizePage - ${sizePage}`);

		// do the actual fetch
		const response = await adalApiFetch(fetchUrl, fetchOptions);

		if (!response.ok) {
			throw Error(response.statusText);
		}

		const r = await response.json();

		//console.log('Fetched me ' + JSON.stringify(r));

		//TODO - we noticed a situation where the OData endpoint was not behaving properly and was returning a raw []
		return ({
			totalCount: r['@odata.count'] ?? 0,
			sizePage: sizePage,
			totalPages: Math.ceil((r['@odata.count'] ?? 0) / sizePage),
			currentPage: pageToFetch,
			listData: r['value'] ?? [],
			error: false, 
			errorInfo: null,
		});
	}
	catch (err) {
		// log the err and perform other recovery; also notify the user if necessary
		console.log(err);

		return ({
			totalCount: 0,
			sizePage: sizePage,
			totalPages: 0,
			currentPage: 0,
			listData: [],
			error: true
		});
	}
}

/**
 * A helper function that builds up a complete OData request URL
 * 
 * @param fetchUrl the fully composed URL representing the resource to fetch from the API endpoint
 * @param pageToFetch since all results are paged, this indicates the page to be fetch; it works with [[sizePage]]
 * @param sizePage the size of the page to be returned; it goes hand in hand with [[pageToFetch]]
 * @returns returns List data fetched from the API OData endpoint
 */
export async function fetchApiRestListData(fetchUrl: string, pageToFetch: number, sizePage: number): Promise<IApiListReturn> {
	try {
		let fetchOptions = { 
			method: 'GET', 
			//mode: 'no-cors' 
			headers: {
				'Accept': 'application/json',
			},
		};

		//console.log(`fetchApiRestListData: page - ${pageToFetch}, sizePage - ${sizePage}`);

		// do the actual fetch
		//const response = await apiFetch(accessToken, fetchUrl, fetchOptions);
		const response = await adalApiFetch(fetchUrl, fetchOptions);

		if (!response.ok) {
			throw Error(response.statusText);
		}

		const r = await response.json();

		//console.log('Fetched me ' + JSON.stringify(r));

		return ({
			totalCount: r['TotalCount'] ?? 0,
			sizePage: r['PageSize'] ?? sizePage,
			totalPages: r['TotalPages'] ?? Math.ceil((r['TotalCount'] ?? 0) / sizePage),
			currentPage: r['CurrentPage'] ?? pageToFetch,
			listData: r['Items'] ?? [],
			error: false,
			errorInfo: null,
		});
	}
	catch (err) {
		// log the err and perform other recovery; also notify the user if necessary
		console.log(err);

		return ({
			totalCount: 0,
			sizePage: sizePage,
			totalPages: 0,
			currentPage: 0,
			listData: [],
			error: true,
			errorInfo: err,
		});
	}
}

/**
 * A helper function that builds up a complete OData request URL
 * 
 * @remarks
 * This function is called by [[fetchApiEntityData]]
 * 
 * @param resource the resource that returns a list
 * @param id the id of the entity on this resource endpoint
 * @param selectFields the entity fields to select for return
 * @param expandProps the navigation properties the OData endpoint should expand before returning results
 * @returns returns a completely built Entity OData URL and query
 */
export function getApiEntityUrl(resource: string, id: any): string {
	if ($isNullOrEmpty(resource)) 
		throw new Error('resource must be specified.');
	
	if ($isNullOrEmpty(id)) 
		throw new Error('id must be specified.');
	
	let fetchUrl = `${endpoints.queryEndpoint}/${resource}(${id})`;

	return fetchUrl;
}

/**
 * A helper function that builds up a complete OData request URL
 * 
 * @param resource the resource that returns a list
 * @returns returns a completely built OData URL and query
 */
export function getApiGeneralUrl(resource: string): string {
	if ($isNullOrEmpty(resource)) 
		throw new Error('resource must be specified.');
	
	let fetchUrl = `${endpoints.queryEndpoint}/${resource}`;

	return fetchUrl;
}

/**
 * A helper function that builds up a complete OData request URL
 * 
 * @param resource the resource that returns a list
 * @param priorClause a boolean indicating whether [resource] already contains an OData query/parameter/clause
 * @param selectFields the entity fields to select for return
 * @param expandProps the navigation properties the OData endpoint should expand before returning results
 * @returns returns a completely built OData URL and query
 */
 export function getApiOdataGeneralUrl(resource: string, priorClause: boolean, selectFields: string[] = [], expandProps: string[] = []): string {
    if ($isNullOrEmpty(resource)) 
        throw new Error('resource must be specified.');
    
	let fetchUrl = `${endpoints.queryEndpoint}/${resource}`;

	//select, expand
	fetchUrl = addOdataSelectExpandClauses(fetchUrl, priorClause, selectFields, expandProps);

	return fetchUrl;
}

/**
 * An interface describing the fetchApiEntityData return data.
 */
export interface IApiEntityReturn {
	entityData: any;
	error: boolean;
	errorInfo?: any;
}

/**
 * A helper function that builds up a complete OData request URL
 * 
 * @param fetchUrl the complete URL representing the resource to fetch from the API endpoint
 * @returns returns Entity data fetched from the API OData endpoint
 */
export const fetchApiEntityData = async (fetchUrl: string): Promise<IApiEntityReturn> => {
	try {
		let fetchOptions = {
			method: 'GET',
			//mode: 'no-cors' 
			headers: {
				'Accept': 'application/json',
			},
		};

		// do the actual fetch
		//const response = await apiFetch(accessToken, fetchUrl, fetchOptions);
		const response = await adalApiFetch(fetchUrl, fetchOptions);

		if (!response.ok) {
			throw Error(response.statusText);
		}

		const r = await response.json();

		//console.log('Fetched me ' + JSON.stringify(r));
		return ({
			entityData: r,
			error: false,
		});
	}
	catch (err) {
		// log the err and perform other recovery; also notify the user if necessary
		console.log(err);

		return ({
			entityData: {},
			error: true
		});
	}
};


/**
 * An interface describing the fetchApiFile return data.
 */
export interface IApiFileReturn {
	filename: string;
	blob: any;
	error: boolean;
}

export const fetchApiFile = async (fetchUrl: string, actionParams: any = null): Promise<IApiFileReturn> => {
	try {
		//by default, we do a get
		let fetchOptions: any = {
			method: 'GET',
			//mode: 'no-cors',
			headers: {
				'Accept': 'application/json',
			},
		};

		//when Params are specified, we switch to a post to pass them along
		if (!($isNull(actionParams) || Object.entries(actionParams).length == 0)) {
			fetchOptions = {
				method: 'POST',
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					//'Content-Type': 'application/json;IEEE754Compatible=true',
				},
				body: JSON.stringify(actionParams),
			}
		}

		//const response = await apiFetch(accessToken, fetchUrl, fetchOptions);
		const response = await adalApiFetch(fetchUrl, fetchOptions);

		if (!response.ok) {
			throw Error(response.statusText);
		}

		const blob = await response.blob();

		//console.log(resp.headers);
		//content-disposition:	   0	 ;				1						;					2
		//content-disposition: attachment; filename=CmpAccountComm_20200527.xlsx; filename*=UTF-8''CmpAccountComm_20200527.xlsx
		const contDispArray = response.headers.get('content-disposition').split('; ');
		const filenameInterim = (contDispArray[1]).split('=')[1];

		const startIndex = filenameInterim.startsWith('"') ? 1 : 0;
		const stopIndex = filenameInterim.length - (filenameInterim.endsWith('"') ? 1 : 0);

		const filename = filenameInterim.substring(startIndex, stopIndex);

		//console.log(`|${resp.headers.get('content-disposition')}|`);
		//console.log(`|${filenameInterim}|`);
		//console.log(`|${filename}|`);

		return ({
			filename,
			blob,
			error: false,
		});
	}
	catch (err) {
		// log the err and perform other recovery; also notify the user if necessary
		console.log(err);

		return ({
			filename: null,
			blob: null,
			error: true
		});
	}
};


/**
 * An interface describing the executeApi* return data.
 */
export interface IApiActionReturn {
	return: any;
	status: number;
	error: boolean;
}

/**
 * A function that dispatches a call to the given url using the specified HTTP [method] and [actionParams]
 * 
 * @param fetchUrl the URL against which to execute the Api Action
 * @param actionParams the parameters to be passed to the Api Action
 * @param method the HTTP [method] to use for the call
 * @returns returns [IApiActionReturn] containing data about the success or otherwise of the Api Action
 */
export const executeApiAction = async (fetchUrl: string, actionParams: any, method?: string): Promise<IApiActionReturn> => {
	method = method || 'POST';

	try {
		let fetchOptions: RequestInit = {
			method: method,
			//mode: 'no-cors',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				//'Content-Type': 'application/json;IEEE754Compatible=true',
			},
			body: JSON.stringify(actionParams),
		};

		// do the actual execution
		//const response = await apiFetch(accessToken, fetchUrl, fetchOptions);
		const response = await adalApiFetch(fetchUrl, fetchOptions);

		// console.log(`Response ${response.status} for ${response.url}`);

		// if (response.status >= 400)
		// 	console.log(await response.text());

		//console.log(`Response Headers for: [${method}] ${fetchUrl}`, response.headers);

		const responseType = (response.headers.get('content-type') || '_').split('; ')[0];

		return { return: await (responseType === 'application/json' ? response.json() : response.text()), status: response.status, error: false };
	}
	catch (err) {
		// log the err and perform other recovery; also notify the user if necessary
		console.log(`Error executing: [${method}] ${fetchUrl}`)
		console.log(err);

		return { return: err, status: -NullEntityId, error: true };
	}
};

export function actionReturnToErrorString(apiReturn: IApiActionReturn, cmdUseRestEndpoint: boolean) {
	return (cmdUseRestEndpoint
	? (apiReturn.return?.Detail || apiReturn.return?.Message)
	: (apiReturn.return?.detail || apiReturn.return?.message)) || apiReturn.return;
}

/**
	* A function that builds and dispatches the named action to the [id]'ed resource on the Api endpoint
	* 
	* @param resource the resource against which to carry out the operation
	* @param id the id of the resource against which to carry out the operation
	* @param action the name of the action to perform (defined in SysActions)
	* @param actionParams the parameters to be passed to the Api Action
	* @returns returns [IApiActionReturn] containing data about the success or otherwise of the Api Action
	*/
export async function executeApiOdataEntityAction(resource: string, id: any, action: string, actionParams: any): Promise<IApiActionReturn> {
	let fetchUrl = `${endpoints.queryEndpoint}/${resource}(${id})` + (action == SysActions.UPDATE || action == SysActions.DELETE ? '' : `/${action}()`);

	return executeApiAction(
		fetchUrl,
		actionParams,
		action == SysActions.UPDATE ? 'PUT'
			: action == SysActions.DELETE ? 'DELETE'
				: 'POST'
	)
}

/**
	* A function that builds and dispatches the named action to the [id]'ed resource on the Api endpoint
	* 
	* @param resource the resource against which to carry out the operation
	* @param id the id of the resource against which to carry out the operation
	* @param action the name of the action to perform (defined in SysActions)
	* @param actionParams the parameters to be passed to the Api Action
	* @returns returns [IApiActionReturn] containing data about the success or otherwise of the Api Action
	*/
export async function executeApiRestEntityAction(resource: string, id: any, action: string, actionParams: any): Promise<IApiActionReturn> {
	let fetchUrl = `${endpoints.cmdEndpoint}/${resource}(${id})` + (action == SysActions.UPDATE || action == SysActions.DELETE ? '' : `/${action}`);

	return executeApiAction(
		fetchUrl,
		actionParams,
		action == SysActions.UPDATE ? 'PUT'
			: action == SysActions.DELETE ? 'DELETE'
				: 'POST'
	)
}

/**
	* A function that builds and dispatches the named action to the named resource set on the Api endpoint
	* 
	* @param resource the resource set against which to carry out the operation
	* @param action the name of the action to perform (defined in SysActions)
	* @param actionParams the parameters to be passed to the Api Action
	* @returns returns [IApiActionReturn] containing data about the success or otherwise of the Api Action
	*/
export async function executeApiEntitySetAction(resource: string, action: string, actionParams: any): Promise<IApiActionReturn> {
	let fetchUrl = `${endpoints.cmdEndpoint}/${resource}` + ($isNullOrEmpty(action) ? '' : `/${action}`);

	return executeApiAction(fetchUrl, actionParams)
}

/**
	* A function that builds and dispatches the named action to the named resource set on the Api endpoint
	* 
	* @param resource the resource set against which to carry out the operation
	* @param action the name of the action to perform (defined in SysActions)
	* @param actionParams the parameters to be passed to the Api Action
	* @returns returns [IApiActionReturn] containing data about the success or otherwise of the Api Action
	*/
export async function executeApiOdataEntitySetAction(resource: string, action: string, actionParams: any): Promise<IApiActionReturn> {
	let fetchUrl = `${endpoints.queryEndpoint}/${resource}` + ($isNullOrEmpty(action) ? '' : `/${action}()`);

	return executeApiAction(fetchUrl, actionParams)
}

/**
	* A function that builds and dispatches the named action to the named resource set on the Api endpoint
	* 
	* @param resource the resource set against which to carry out the operation
	* @param action the name of the action to perform (defined in SysActions)
	* @param actionParams the parameters to be passed to the Api Action
	* @returns returns [IApiActionReturn] containing data about the success or otherwise of the Api Action
	*/
export async function executeApiRestEntitySetAction(resource: string, action: string, actionParams: any): Promise<IApiActionReturn> {
	let fetchUrl = `${endpoints.cmdEndpoint}/${resource}` + ($isNullOrEmpty(action) ? '' : `/${action}`);

	return executeApiAction(fetchUrl, actionParams)
}

/**
	* A union type indicating the possible version of the Microsoft Graph
	*/
export type MsGraphVersion = 'v1.0' | 'beta';

/*
	$count is not rejected but works only on certain entities
	$skip is selectively supported for various entities
	$filter doesn't support all operators and we cannot be arbitrary in our combination of values
	$orderby is supported for specific properties
	$top is valid everywhere
	$skipToken is generated server-side don't bother, just use nextlink -> nextUrl
*/

/**
	* A helper function that builds up a complete Microsoft Graph request URL
	* 
	* @param resource the resource that returns a list
	* @param version 
	* @param sizePage the size of the page to be returned
	* @param orderBy the fields to use to order the returned list
	* @param selectFields the entity fields to select for return
	* @param expandProps the navigation properties the OData endpoint should expand before returning results
	* @param filterExpressions some prebuilt filter expressions to be appended to the $filter clause
	* @returns returns a completely built List Microsoft Graph URL and query
	*/
export function getGraphListUrl(resource: string, version: MsGraphVersion = 'beta', sizePage: number = null, orderBy: string = null, selectFields: string[] = [], expandProps: string[] = [], filterExpressions: string[] = []): string {
	if ($isNullOrEmpty(resource)) 
		throw new Error('resource must be specified.');
	
	if (!$isNull(sizePage) && sizePage < 1) 
		throw new Error('sizePage must be null or greater than 0');
	
	if ($isNullOrEmpty(version)) 
		version = 'beta';
	
	let fetchUrl = `${endpoints.msgraph}/${version}/${resource}?$count=true`;

	//orderby
	if (!$isNullOrEmpty(orderBy)) {
		fetchUrl = fetchUrl + `&$orderby=${orderBy}`;
	}

	//top
	if (!$isNull(sizePage)) {
		fetchUrl = fetchUrl + `&$top=${sizePage}`;
	}

	//select, expand
	fetchUrl = addOdataSelectExpandClauses(fetchUrl, true, selectFields, expandProps);

	// search
	fetchUrl = fetchUrl + makeOdataFilterClause('', [], filterExpressions);

	return fetchUrl;
}

/**
	* An interface describing the fetchGraphList return data.
	*/
export interface IGraphListReturn {
	totalCount: number;
	nextPageUrl: string;
	sizePage: number;
	list: any[];
	error: boolean;
}

/**
	* A helper function that dispatches a call to Microsoft Graph with appropriate headers set.
	* 
	* @param fetchUrl the resource/url that returns a data list from Microsoft Graph
	* @param sizePage the size of the page to be returned
	* @returns returns an object with a list property containing parsed JSON data returned from the Microsoft graph endpoint. Other properties are metadata about the call.
	*/
export async function fetchGraphList(accessToken: string, fetchUrl: string, sizePage: number = null): Promise<IGraphListReturn> {
	try {
		let fetchOptions = { 
			method: 'GET', 
			//mode: 'no-cors' 
			headers: {
				'Accept': 'application/json',
			},
		};

		// do the actual fetch
		//const response = await apiFetch(accessToken, fetchUrl, fetchOptions);
		const response = await adalGraphFetch(fetchUrl, fetchOptions);

		if (!response.ok) {
			throw Error(response.statusText);
		}

		const r = await response.json();

		//console.log('Fetched me ' + JSON.stringify(r));
		return ({
			totalCount: r['@odata.count'],
			nextPageUrl: r['@odata.nextLink'],
			sizePage: sizePage,
			list: r['value'],
			error: false,
		});
	}
	catch (err) {
		// log the err and perform other recovery; also notify the user if necessary
		console.log(err);

		return ({
			totalCount: 0,
			nextPageUrl: null,
			sizePage: sizePage,
			list: [],
			error: true
		});
	}
}

/**
	* A helper function that builds up a complete Microsoft Graph request URL
	* 
	* @param resource the resource that returns a list
	* @param id the id of the particular resource we are interested in 
	* @param version the version of the Microsoft Graph API to call of type [MsGraphVersion]
	* @param selectFields the entity fields to select for return
	* @param expandProps the navigation properties the OData endpoint should expand before returning results
	* @returns returns a completely built List Microsoft Graph URL and query
	*/
export function getGraphEntityUrl(resource: string, id: any, version: MsGraphVersion = 'beta', selectFields: string[] = [], expandProps: string[] = []): string {
	if ($isNullOrEmpty(resource)) 
		throw new Error('resource must be specified.');
	
	if ($isNullOrEmpty(id)) 
		throw new Error('id must be specified.');
	
	if ($isNullOrEmpty(version)) 
		version = 'beta';
	
	let fetchUrl = `${endpoints.msgraph}/${version}/${resource}(${id})`;

	//select, expand
	fetchUrl = addOdataSelectExpandClauses(fetchUrl, false, selectFields, expandProps);

	return fetchUrl;
}

/**
	* A helper function that builds up a complete Microsoft Graph request URL
	* 
	* @param resource the resource that returns desired data; it may contain query parameters already indicated by [priorClause]
	* @param version the version of the Microsoft Graph API to call of type [MsGraphVersion]
	* @param priorClause a boolean indicating whether [resource] already contains an OData query/parameter/clause
	* @param selectFields the entity fields to select for return
	* @param expandProps the navigation properties the OData endpoint should expand before returning results
	* @returns returns a completely built List Microsoft Graph URL and query
	*/
export function getGraphGeneralUrl(resource: string, version: MsGraphVersion = 'beta', priorClause: boolean, selectFields: string[] = [], expandProps: string[] = []): string {
	if ($isNullOrEmpty(resource)) 
		throw new Error('resource must be specified.');
	
	if ($isNullOrEmpty(version)) 
		version = 'beta';
	
	let fetchUrl = `${endpoints.msgraph}/${version}/${resource}`;

	//select, expand
	fetchUrl = addOdataSelectExpandClauses(fetchUrl, priorClause, selectFields, expandProps);

	return fetchUrl;
}

/**
	* An interface describing the fetchGraphData return data.
	*/
export interface IGraphDataReturn {
	data: any;
	error: boolean;
}

/**
	* A helper function that builds up a complete OData request URL
	* 
	* @param fetchUrl the complete URL representing the resource to fetch from the Microsoft Graph endpoint
	* @returns returns data fetched from the Graph OData endpoint wrapped as [IGraphDataReturn]
	*/
export async function fetchGraphData(accessToken: string, fetchUrl: string): Promise<IGraphDataReturn> {
	try {
		let fetchOptions = { 
			method: 'GET', 
			//mode: 'no-cors' 
			headers: {
				'Accept': 'application/json',
			},
		};

		// do the actual fetch
		//const response = await apiFetch(accessToken, fetchUrl, fetchOptions);
		const response = await adalGraphFetch(fetchUrl, fetchOptions);

		if (!response.ok) {
			throw Error(response.statusText);
		}

		const r = await response.json();

		//console.log('Fetched me ' + JSON.stringify(r));
		return ({
			data: r,
			error: false,
		});
	}
	catch (err) {
		// log the err and perform other recovery; also notify the user if necessary
		console.log(err);

		return ({
			data: null,
			error: true
		});
	}
}
