import { FunctionComponent, Suspense } from 'react';
import { HashRouter } from "react-router-dom";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import Spinner from "app-views/spinner/Spinner";

import { handleLogout, msalConfig, msalInstance } from 'adalConfig';
import { LogonUnauthenticatedRouteMap } from 'app-layouts/LogonUnauthenticatedRouteMap';
import { LogonAuthenticatedRouteMap } from 'app-layouts/LogonAuthenticatedRouteMap';

// const msalInstance = new PublicClientApplication(msalConfig);

const App: FunctionComponent = () => {
	const { inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	// handleLogout(msalInstance);

	return (
		<HashRouter>
			<Suspense fallback={<Spinner />}>
				{
					(isAuthenticated) ? <LogonAuthenticatedRouteMap />
					: <LogonUnauthenticatedRouteMap />
				}
			</Suspense>
		</HashRouter>
	);
};

export default App;
