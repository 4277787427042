import { lazy } from "react";

const OnboardView = lazy(() => import("app-modules/onboard/OnboardView"));

const moduleRoutes = 	{
	//collapse: true,
	template: "/onboard/:id/view/*",
	path: "/onboard/999999999/view",
	name: "Onboarding",
	state: "onboardpages",
	icon: "codepen",
	component: OnboardView,
};

export default moduleRoutes;
