import { combineReducers } from 'redux';

import { toIsoDateString } from 'app-helpers/basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { DefNgNseExchangeId, EnumAllOptionValue, NullEntityId, oneYearAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-redux/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists: IListComponentControlList = {
	BeneficiaryList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'BeneficiaryList',
		entityName: 'OnboardGTBeneficiary',
		cmdUriTemplate: 'OnboardGTTrust/Active/Beneficiary',
		fetchUriTemplate: 'OnboardGTTrust/Active/Beneficiary',
		saveUriTemplate: 'OnboardGTTrust/Active/Beneficiary',
		filterValues: {
			searchText: '',
		},
	},

	PersonalPropertyList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'PersonalPropertyList',
		entityName: 'OnboardWillPersonalProperty',
		cmdUriTemplate: 'OnboardWill/Active/PersonalProperty',
		fetchUriTemplate: 'OnboardWill/Active/PersonalProperty',
		saveUriTemplate: 'OnboardWill/Active/PersonalProperty',
		filterValues: {
			searchText: '',
		},
	},

	RealPropertyList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'RealPropertyList',
		entityName: 'OnboardWillRealProperty',
		cmdUriTemplate: 'OnboardWill/Active/RealProperty',
		fetchUriTemplate: 'OnboardWill/Active/RealProperty',
		saveUriTemplate: 'OnboardWill/Active/RealProperty',
		filterValues: {
			searchText: '',
		},
	},

};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction): IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews: IViewComponentControlList = {
	ClientInfoView: {
		...fluxViewDefaults,
		actionNamespace: 'ClientInfoView',
		entityName: 'OnboardWill',
		fetchUriTemplate: 'OnboardWill/Active',
		fetchUriComposited: true,
		saveUriTemplate: 'OnboardWill/Active',
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction): IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
