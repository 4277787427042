import { $isNull, $isNullOrEmpty } from "app-helpers/basicFunctions";
import { 
	appendQueryFromFilterValues, fetchApiFile, fullSubDataPageSize, getApiOdataGeneralUrl, getApiOdataListUrl, getApiOdataSimpleUrl, getApiRestListUrl, getApiRestSimpleUrl, IApiFileReturn, substituteFilterValues 
} from "app-helpers/apiHelpers";
import { NullEntityId } from "app-model/ModelConstants";
import { doApiEntityFetch, doApiOdataListFetch, doApiRestListFetch } from "app-redux/actions";

import { IComponentControl, IFluxComponentProps, IListComponentControl, IViewComponentControl } from "app-framework/AppBaseInterfaces";

export const ensureRequiredGlobals = (props: IFluxComponentProps) => {
	if (!$isNull(props.requiredGlobals)) {
		console.log('Required globals - ', props.requiredGlobals);

		const globalEntries = Object.entries(props.requiredGlobals);

		for (const ge of globalEntries) {
			const rg1 = ge[1] as IComponentControl;

			//console.log(rgc);

			if (rg1.fetched == false && rg1.isLoading == false) {
				//console.log('Fetching - ' + rgc.actionNamespace);

				if (String(rg1.actionNamespace).endsWith('List')) {
					const rgc = rg1 as IListComponentControl;

					
					const getListUrl =
						rgc.queryUseRestEndpoint
						? (ptf: number, sp: number, fv: any) => getApiRestListUrl(rgc.fetchUriTemplate, '', ptf, sp)
						: (ptf: number, sp: number, fv: any) => getApiOdataListUrl(rgc.fetchUriTemplate, rgc.orderBy, '', [], ptf, sp, rgc.selectFields, rgc.expandProps, rgc.filterExpressions);

					if (rgc.queryUseRestEndpoint)
						props.dispatch(
							doApiRestListFetch(rgc.actionNamespace, getListUrl, 1, fullSubDataPageSize, {})
						);
					else
						props.dispatch(
							doApiOdataListFetch(rgc.actionNamespace, getListUrl, 1, fullSubDataPageSize, {}, rgc.filterExpressions)
						);
				}
				else {
					const rgc = rg1 as IViewComponentControl;
					
					const getGeneralUrl =
						rgc.queryUseRestEndpoint
						? (id: number) => getApiRestSimpleUrl(rgc.fetchUriTemplate)
						: (id: number) => getApiOdataGeneralUrl(rgc.fetchUriTemplate, false, rgc.selectFields, rgc.expandProps);

					props.dispatch(
						doApiEntityFetch(rgc.actionNamespace, getGeneralUrl, NullEntityId/*an id has to be passed but we know we are not using it*/, null)
					);
				}
			}
		}
	}
};

export const handleFileReturn = async (ret: IApiFileReturn) => {
	if (ret.error == false) {
		const blobUrl = URL.createObjectURL(ret.blob);
	
		const blobLink = document.createElement("a");
	
		blobLink.download = ret.filename;
		blobLink.href = blobUrl;
	
		document.body.appendChild(blobLink);
		blobLink.click();
		document.body.removeChild(blobLink);
	
		//window.open(blobUrl, '_self');	
	}
	else {
		alert("There was an error fetching the report from the server.");
	}
};

export const doDownloadFromApi = async (exportUri: string, actionParams: any = null) => {
	// console.log('fetchApiFile actionParams - ', actionParams);

	// console.log('fetchApiFile actionParams JSON - ', JSON.stringify(actionParams));

	const ret = await fetchApiFile(exportUri, actionParams);

	handleFileReturn(ret);
};


export const getReduxFetchListUrl = (
	controlState: IListComponentControl,
	getListUrlExtraFilters: (filterValue: any) => string,
	pageToFetch: number, 
	sizePage: number, 
	filterValues: any, 
	filterExpressions: string[] = []
): string => {
	const { queryUseRestEndpoint, fetchUriTemplate, totalPages, orderBy, searchFields, selectFields, expandProps } = controlState;

	//console.log(`getReduxListUrl: page - ${pageToFetch}, sizePage - ${sizePage}`);

	if (pageToFetch !== 1 && (pageToFetch < 1 || pageToFetch > totalPages))
		return;

	const { searchText } = filterValues;

	let resourceEndpoint = substituteFilterValues(fetchUriTemplate, filterValues);
	//console.log(resourceEndpoint);

	let fetchUrl = '';

	if (queryUseRestEndpoint) {
		resourceEndpoint = appendQueryFromFilterValues(resourceEndpoint, filterValues);

		fetchUrl = getApiRestListUrl(resourceEndpoint, '', pageToFetch, sizePage);
	}
	else {
		//we have opted to add the getListUrlExtraFilters result to the filterExpressions array
		//the 1 eq 1 expression is compensating for the original design of our resource url generator that assumed an automatic filter expression of 1 eq 1.
		//the 1 eq 1 expression was subsequently removed to allow the same code work on graph.microsoft.com and other OData endpoints; however, it breaks some existing code
		let extraFilterString = $isNull(getListUrlExtraFilters) ? null : getListUrlExtraFilters(filterValues).trimStart();

		if (!$isNullOrEmpty(extraFilterString) && extraFilterString.startsWith('and'))
			extraFilterString = extraFilterString.substring(3);
	
		fetchUrl = getApiOdataListUrl(
			resourceEndpoint,
			orderBy,
			searchText,
			searchFields,
			pageToFetch,
			sizePage,
			selectFields,
			expandProps,
			$isNullOrEmpty(extraFilterString) ? filterExpressions : [...(filterExpressions || []), extraFilterString]
		);
	}

	return fetchUrl;
};

export const getFluxExportUrl = (controlState: IComponentControl, filterValues: any): string => {
	const { exportUriTemplate } = controlState;

	//console.log('exportUriTemplate - ', exportUriTemplate);
	//console.log('filterValues - ', filterValues);

	let resourceEndpoint = substituteFilterValues(exportUriTemplate, filterValues);

	resourceEndpoint = appendQueryFromFilterValues(resourceEndpoint, filterValues);

	//console.log('resourceEndpoint - ', resourceEndpoint);

	let fetchUrl = getApiOdataSimpleUrl(resourceEndpoint);

	return fetchUrl;
};

export const getFluxPrintUrl = (controlState: IComponentControl, filterValues: any): string => {
	const { printUriTemplate } = controlState;

	//console.log('exportUriTemplate - ', exportUriTemplate);
	//console.log('filterValues - ', filterValues);

	const resourceEndpoint = substituteFilterValues(printUriTemplate, filterValues);

	//console.log('resourceEndpoint - ', resourceEndpoint);

	let fetchUrl = getApiOdataSimpleUrl(resourceEndpoint);

	return fetchUrl;
};
