import { getMonthsPrior, getYearsAfter, getYearsPrior, $isNullOrEmpty, getDaysPrior } from 'app-helpers/basicFunctions';

export const EnumAllOptionValue = '(all)';

export const DefNgNseExchangeId = 3220;
export const DefCountryCode = 'NG';

export const NullEntityId = -1000;
export const NullEntityDisplay = '(none)';

export const UndefinedOptionValue = '(undefined)';

export const perpetuityDate = new Date(2099, 1, 1);
export const today = new Date();

export const oneDayAgo = getDaysPrior(new Date(), 1);

export const oneWeekAgo = getDaysPrior(new Date(), 7);

export const oneMonthAgo = getMonthsPrior(new Date(), 1);
export const threeMonthsAgo = getMonthsPrior(new Date(), 3);
export const oneYearAgo = getYearsPrior(new Date(), 1);
export const oneYearFuture = getYearsAfter(new Date(), 1);
export const threeYearsAgo = getYearsPrior(new Date(), 3);
export const tenYearsAgo = getYearsPrior(new Date(), 10);
export const twentyYearsAgo = getYearsPrior(new Date(), 20);


export function $isNullOrEnumAll(val: any): boolean {
	return $isNullOrEmpty(val) || val === 'null' || val === 'undefined' || val === EnumAllOptionValue;
}


export function $isNullOrNullEntityId(val: any): boolean {
	return $isNullOrEmpty(val) || val == NullEntityId;
}
